import React, { useState, useEffect } from 'react'
import '../CalculatorDetailPage/CalculatorDetail.css'
import Header from '../Common/Header/Header'
import Bannerbreadcrump from '../Bannerbreadcrump/Bannerbreadcrump'
import { Link } from 'react-router-dom'
import SearchIcon from '@mui/icons-material/Search'
import sip from '../../../src/Assets/SIP.png'
import lumpsum from '../../../src/Assets/Lumpsum.png'
import swp from '../../../src/Assets/SWP.png'
import mf from '../../../src/Assets/MF.png'
import { Doughnut } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'

// Register necessary components from Chart.js
ChartJS.register(ArcElement, Tooltip, Legend)

const CalculatorDetail = () => {
  const [amount, setAmount] = useState(0)
  const [year, setYear] = useState(0)
  const [interest, setInterest] = useState(0)
  const [monthlyEMI, setMonthlyEMI] = useState('')
  const [totalInterest, setTotalInterest] = useState('')
  const [totalAmount, setTotalAmount] = useState('')
  const [calculator, setCalculator] = useState(false)

  const handleInputChange = (e, setState) => {
    const { value } = e.target
    setState(Number(value))
  }

  useEffect(() => {
    const updateBackground = (elementId, value, min, max, color) => {
      const valBg = ((value - min) / (max - min)) * 100
      document.getElementById(
        elementId
      ).style.background = `linear-gradient(to right, ${color} 0%, ${color} ${valBg}%, #fff ${valBg}%, white 100%)`
    }

    updateBackground('range-slider-amount', amount, 0, 100000, '#f00')
    updateBackground('range-slider-year', year, 0, 30, '#0f0')
    updateBackground('range-slider-interest', interest, 0, 100, '#00f')
  }, [amount, year, interest])

  const handleCalculate = e => {
    e.preventDefault()
    const monthlyRate = interest / 100 / 12
    const totalPayments = year * 12
    const monthly =
      (amount * monthlyRate) / (1 - Math.pow(1 + monthlyRate, -totalPayments))
    const total = monthly * totalPayments
    const interestPaid = total - amount

    setMonthlyEMI(monthly.toFixed(2))
    setTotalInterest(interestPaid.toFixed(2))
    setTotalAmount(total.toFixed(2))
    setCalculator(true)
  }

  const dataChart = {
    labels: ['Interest', 'Principal'],
    datasets: [
      {
        label: '# of Votes',
        data: [totalInterest, totalAmount],
        backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)'],
        borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
        borderWidth: 1
      }
    ]
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false
  }

  return (
    <>
      <Header />
      <Bannerbreadcrump />
      <section className='calc_detail calculator_category'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-4 col-lg-4 col-12'>
              <div className='card__sidebar'>
                <div className='card__common__item bg-white'>
                  <h4 className='head fw-bold bborder title pb-4 mb-4'>
                    Search
                  </h4>
                  <form className='d-flex align-items-center justify-content-between'>
                    <input placeholder='Search' required='' type='text' />
                    <SearchIcon />
                  </form>
                </div>
                <div className='card__common__item bg-white'>
                  <h4 className='head fw-bold bborder title pb-4 mb-4'>
                    Popular Calculator
                  </h4>
                  <ul className='card__categories'>
                    <li>
                      <Link to='' className='d-flex align-items-center'>
                        <i className='material-symbols-outlined d-flex align-items-center justify-content-center base'>
                          <img src={sip} alt='SIP' />
                        </i>
                        <span className='fw-500 title inter'>
                          SIP Calculator
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to='' className='d-flex align-items-center'>
                        <i className='material-symbols-outlined d-flex align-items-center justify-content-center base'>
                          <img src={lumpsum} alt='Lumpsum' />
                        </i>
                        <span className=' title inter'>Lumpsum Calculator</span>
                      </Link>
                    </li>
                    <li>
                      <Link to='' className='d-flex align-items-center'>
                        <i className='material-symbols-outlined d-flex align-items-center justify-content-center base'>
                          <img src={swp} alt='SWP' />
                        </i>
                        <span className='title inter'>SWP Calculator</span>
                      </Link>
                    </li>
                    <li>
                      <Link to='' className='d-flex align-items-center'>
                        <i className='material-symbols-outlined d-flex align-items-center justify-content-center base'>
                          <img src={mf} alt='MF' />
                        </i>
                        <span className=' title inter'>MF Calculator</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='col-xl-8 col-lg-8 col-12'>
              <div className='balance__transfercard p-8 bgwhite mb-40 round16'>
                <div className='calculator-input'>
                  <div
                    className={
                      calculator
                        ? 'card card--custom calc calc_hide'
                        : 'card card--custom calc'
                    }
                  >
                    <div className='card--custom__loan'>
                      <div className='card--custom__form'>
                        <form
                          method='POST'
                          autoComplete='off'
                          id='frmCalculate'
                          className='calculate__form'
                          onSubmit={handleCalculate}
                        >
                          <div className='row gy-5 gy-md-0'>
                            <div className='col-12 col-md-6'>
                              <h2
                                className='title mb-3 wow fadeInDown text-start'
                                data-wow-duration='0.8s'
                                style={{
                                  visibility: 'visible',
                                  animationDuration: '0.8s',
                                  animationName: 'fadeInLeft'
                                }}
                              >
                                Calculator
                              </h2>
                              <div className='calculate__form-part'>
                                <div className='input-single mb-3'>
                                  <label className='label' htmlFor='amount'>
                                    Loan Amount <span>(required)</span>
                                  </label>
                                  <input
                                    type='number'
                                    className='form-control'
                                    name='amount'
                                    id='amount'
                                    placeholder='₹0'
                                    value={amount || ''}
                                    onChange={e =>
                                      handleInputChange(e, setAmount)
                                    }
                                    required
                                  />
                                  <input
                                    type='range'
                                    min='0'
                                    max='100000'
                                    value={amount}
                                    className='range-slider'
                                    id='range-slider-amount'
                                    onChange={e =>
                                      handleInputChange(e, setAmount)
                                    }
                                  />
                                </div>
                                <div className='input-single mb-3'>
                                  <label className='label' htmlFor='year'>
                                    Loan Term <span>(years)</span>
                                  </label>
                                  <input
                                    type='number'
                                    className='form-control'
                                    name='year'
                                    id='year'
                                    placeholder='0 years'
                                    value={year || ''}
                                    onChange={e =>
                                      handleInputChange(e, setYear)
                                    }
                                    required
                                  />
                                  <input
                                    type='range'
                                    min='0'
                                    max='30'
                                    value={year}
                                    className='range-slider'
                                    id='range-slider-year'
                                    onChange={e =>
                                      handleInputChange(e, setYear)
                                    }
                                  />
                                </div>
                                <div className='input-single mb-3'>
                                  <label className='label' htmlFor='interest'>
                                    Interest Rate (%) <span>(required)</span>
                                  </label>
                                  <input
                                    type='number'
                                    className='form-control'
                                    name='interest'
                                    id='interest'
                                    placeholder='0.00%'
                                    value={interest || ''}
                                    onChange={e =>
                                      handleInputChange(e, setInterest)
                                    }
                                    required
                                  />
                                  <input
                                    type='range'
                                    min='0'
                                    max='100'
                                    value={interest}
                                    className='range-slider'
                                    id='range-slider-interest'
                                    onChange={e =>
                                      handleInputChange(e, setInterest)
                                    }
                                  />
                                </div>
                              </div>
                              <button
                                type='submit'
                                className='cmn--btn mt-4 mt-md-5'
                                name='calc_submit'
                                id='calc_submit'
                              >
                                <span> Calculate </span>
                              </button>
                            </div>
                            <div className='col-12 col-md-6'>
                              <h2
                                className='title mb-3 wow fadeInDown text-start'
                                data-wow-duration='0.8s'
                                style={{
                                  visibility: 'visible',
                                  animationDuration: '0.8s',
                                  animationName: 'fadeInLeft'
                                }}
                              >
                                Result
                              </h2>
                              <div
                                className={
                                  calculator
                                    ? 'card card--custom calculator-result calc_show'
                                    : 'card card--custom calculator-result'
                                }
                              >
                                <div style={{ width: '100%', height: '300px' }}>
                                  <Doughnut
                                    data={dataChart}
                                    options={options}
                                  />
                                </div>
                                <div className='calculate__form-part result'>
                                  <div className='input-single'>
                                    <label className='label'>Monthly EMI</label>
                                    <p className='headingFour' id='monthly_emi'>
                                      ₹{monthlyEMI}
                                    </p>
                                  </div>
                                  <div className='input-single'>
                                    <label className='label'>
                                      Principal Amount
                                    </label>
                                    <p
                                      className='headingFour'
                                      id='principal_amount'
                                    >
                                      ₹{amount.toFixed(2)}
                                    </p>
                                  </div>
                                  <div className='input-single'>
                                    <label className='label'>
                                      Total Interest
                                    </label>
                                    <p
                                      className='headingFour'
                                      id='total_interest'
                                    >
                                      ₹{totalInterest}
                                    </p>
                                  </div>
                                  <div className='input-single'>
                                    <label className='label'>
                                      Total Amount
                                    </label>
                                    <p
                                      className='headingFour'
                                      id='total_amount'
                                    >
                                      ₹{totalAmount}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default CalculatorDetail
