import React from 'react'
import { Modal, ModalBody } from 'reactstrap'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import Header from '../Header/Header'
import signinimg from '../../../Assets/signup-thumb.webp'

function SignupModal ({ show, setShowSignup }) {
  const toggleSignupModal = () => setShowSignup(!show)

  return (
    <>
      <Modal
        isOpen={show}
        toggle={toggleSignupModal}
        centered
        size='lg'
        backdrop='static'
        keyboard={true}
      >
        <ModalBody>
          <div className=''>
            <div className='gsc_modalWrapper'>
              <div onClick={toggleSignupModal} className='v2_close false'>
                <a href='#' className='close-button'>
                  <div className='in'>
                    <div className='close-button-block'></div>
                    <div className='close-button-block'></div>
                  </div>
                  <div className='out'>
                    <div className='close-button-block'></div>
                    <div className='close-button-block'></div>
                  </div>
                </a>
              </div>
              <div className='signinmodal_box'>
                <div className='row justify-content-center align-items-center'>
                  <div className='col-lg-6'>
                    <div className='signup__boxes'>
                      <h3 className='title '>Let’s Get Started!</h3>
                      <p className='fs-6 title fw-400 inter mb-4'>
                        Please enter your email address to join us
                      </p>
                      <form action='' className='write__review'>
                        <div className='row g-4 '>
                          <div className='col-lg-12'>
                            <div className='frm__grp'>
                              <label
                                for='name'
                                className='fs-6 fw-500 inter title mb-2'
                              >
                                First Name
                              </label>
                              <input
                                id='name'
                                placeholder='Enter First Name...'
                                required=''
                                type='text'
                              />
                            </div>
                          </div>
                          <div className='col-lg-12'>
                            <div className='frm__grp'>
                              <label
                                for='lname'
                                className='fs-6 fw-500 inter title mb-2'
                              >
                                Last Name
                              </label>
                              <input
                                id='lname'
                                placeholder='Enter Last Name...'
                                required=''
                                type='text'
                              />
                            </div>
                          </div>
                          <div className='col-lg-12'>
                            <div className='frm__grp'>
                              <label
                                for='enamee'
                                className='fs-6 fw-500 inter title mb-2'
                              >
                                Enter Your Email ID
                              </label>
                              <input
                                id='enamee'
                                placeholder='Enter Your Email...'
                                required=''
                                type='text'
                              />
                            </div>
                          </div>
                          <div className='col-lg-12'>
                            <div className='frm__grp inputPassword'>
                              <label
                                for='pas'
                                className='fs-6 fw-500 inter title mb-2'
                              >
                                Enter Your Password
                              </label>
                              <input
                                id='pas'
                                placeholder='Enter Your Password...'
                                required=''
                                type='password'
                              />
                              <div className='toggle-icon'>
                                <i className='material-symbols-outlined'>
                                  <VisibilityOffIcon />
                                </i>
                              </div>
                            </div>
                          </div>
                          <p className='fs-6 fw-400 title inter'>
                            Do you have an account?{' '}
                            <a className='base' href='/singin'>
                              Signin
                            </a>
                          </p>
                          <div className='col-lg-6'>
                            <div className='frm__grp'>
                              <button
                                aria-label='Sing up'
                                type='submit'
                                className='cmn--btn'
                              >
                                <span>Sign Up</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <div className='signup__thumb'>
                      <img
                        alt='img'
                        loading='lazy'
                        width='784'
                        height='636'
                        decoding='async'
                        data-nimg='1'
                        className='img-fluid'
                        src={signinimg}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default SignupModal
