import React from 'react'
import Slider from 'react-slick'
import '../CompanyBrand/CompanyBrand.css'
import Brand1 from '../../../src/Assets/brand1.webp'
import Brand2 from '../../../src/Assets/brand2.webp'
import Brand3 from '../../../src/Assets/brand3.webp'
import { Link } from 'react-router-dom'

const CompanyBrand = () => {
  var companySlider = {
    dots: false,
    nav: false,
    arrows: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 2000,
    spacebetween: 20,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      }
    ]
  }
  return (
    <div className='CompanyBrand'>
      <div className='container'>
        <div className='row align-items-center gap-2 gap-md-4 gap-lg-0'>
          <div className='col-md-4'>
            <div className='section__title mb-0'>
              <h4 className='sub ralt base wow fadeInUp'>Company Brand</h4>
              <p className='ptext2 fs-6 fw-400 inter wow fadeInUp'>
                businesses from small startups to household names
              </p>
            </div>
          </div>
          <div className='col-md-8'>
            <div className='company_slider'>
              <Slider {...companySlider}>
                <div className='company_brand'>
                  <Link
                    to=''
                    className='d-flex align-items-center justify-content-center'
                  >
                    <img src={Brand1} />
                  </Link>
                </div>
                <div className='company_brand'>
                  <Link
                    to=''
                    className='d-flex align-items-center justify-content-center'
                  >
                    <img src={Brand2} />
                  </Link>
                </div>
                <div className='company_brand'>
                  <Link
                    to=''
                    className='d-flex align-items-center justify-content-center'
                  >
                    <img src={Brand3} />
                  </Link>
                </div>
                <div className='company_brand'>
                  <Link
                    to=''
                    className='d-flex align-items-center justify-content-center'
                  >
                    <img src={Brand1} />
                  </Link>
                </div>
                <div className='company_brand'>
                  <img src={Brand3} />
                </div>
                <div className='company_brand'>
                  <Link
                    to=''
                    className='d-flex align-items-center justify-content-center'
                  >
                    <img src={Brand2} />
                  </Link>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompanyBrand
