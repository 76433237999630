import React from 'react'
import '../About/About.css'
import Header from '../Common/Header/Header'
import Bannerbreadcrump from '../Bannerbreadcrump/Bannerbreadcrump'
import wechooseimg from '../../../src/Assets/we-thumb.webp'
import AddCardIcon from '@mui/icons-material/AddCard'
import LegendToggleIcon from '@mui/icons-material/LegendToggle'
import HandshakeIcon from '@mui/icons-material/Handshake'
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech'
import Worksection from '../Worksection/Worksection'
import ReviewSection from '../ReviewSection/ReviewSection'
import { Download } from '@mui/icons-material'
import Downloadapp from '../DownloadApp/Downloadapp'
import CompanyBrand from '../CompanyBrand/CompanyBrand'
import Calculator from '../Calculator/Calculator'
import Contactbanner from '../Contactbanner/Contactbanner'
import Footer from '../Common/Footer/Footer'

const About = () => {
  return (
    <>
      <Header />
      <Bannerbreadcrump />
      <CompanyBrand />
      <section className='provide__section ralt bgadd'>
        <div className='container'>
          <div className='row g-4 flex-row-reverse align-items-center justify-content-between'>
            <div className='col-xl-6 col-lg-7'>
              <div className='we__counting'>
                <div className='choose__content'>
                  <div className='section__title'>
                    <h4 className='sub ralt base wow fadeInUp'>Who We Are</h4>
                    <h2 className='title  wow fadeInDown '>
                      Trusted Credit Cards To Help You Achieve Your Goals
                    </h2>
                    <p className='ptext2 fs-6 fw-400 inter wow fadeInUp mb-4'>
                      We understand that the world of credit cards can be
                      overwhelming, with countless options available and complex
                      terms and conditions. That's why we're here to guide you
                      through the process and help you make informed decisions.
                    </p>
                  </div>
                </div>
                <div className='row g-4 justify-content-center mb-5 mb-lg-0'>
                  <div className='col-xxl-6 col-xl-3 col-lg-6 col-md-6 col-sm-6'>
                    <div className='counter__items odometer-item wow fadeInDown'>
                      <div className='counter__content d-flex align-items-center'>
                        <div className='iconbox d-flex align-items-center justify-content-center bg-white'>
                          <i class='material-symbols-outlined base'>
                            <AddCardIcon className='card_icon' />
                          </i>
                        </div>
                        <div className='content'>
                          <div className='d-flex mb-1 align-items-center'>
                            <span className='odometer base'>
                              {' '}
                              <span>2.5</span>
                            </span>
                            <span className='added base'>k</span>
                          </div>
                          <span className='fs-6 fw-400 ptext2 inter'>
                            Total Card
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-xxl-6 col-xl-3 col-lg-6 col-md-6 col-sm-6'>
                    <div className='counter__items odometer-item wow fadeInUp'>
                      <div className='counter__content d-flex align-items-center'>
                        <div className='iconbox d-flex align-items-center justify-content-center bg-white'>
                          <i className='material-symbols-outlined icolor1'>
                            <LegendToggleIcon className='card_icon' />
                          </i>
                        </div>
                        <div className='content'>
                          <div className='d-flex mb-1 align-items-center'>
                            <span className='odometer icolor1'>
                              <span>3.8</span>
                            </span>
                            <span className='added icolor1'>k</span>
                          </div>
                          <span className='fs-6 fw-400 ptext2 inter'>
                            Total Sale
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-xxl-6 col-xl-3 col-lg-6 col-md-6 col-sm-6'>
                    <div className='counter__items odometer-item wow fadeInDown'>
                      <div className='counter__content d-flex align-items-center'>
                        <div className='iconbox d-flex align-items-center justify-content-center bg-white'>
                          <i className='material-symbols-outlined icolor2'>
                            <HandshakeIcon className='card_icon' />
                          </i>
                        </div>
                        <div className='content'>
                          <div className='d-flex mb-1 align-items-center'>
                            <span className='odometer icolor2'>
                              {' '}
                              <span>0.5</span>
                            </span>
                            <span className='added icolor2'>k</span>
                          </div>
                          <span className='fs-6 fw-400 ptext2 inter'>
                            Client Satisfied
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-xxl-6 col-xl-3 col-lg-6 col-md-6 col-sm-6'>
                    <div className='counter__items odometer-item wow fadeInUp'>
                      <div className='counter__content d-flex align-items-center'>
                        <div className='iconbox d-flex align-items-center justify-content-center bg-white'>
                          <i className='material-symbols-outlined base'>
                            <MilitaryTechIcon className='card_icon' />
                          </i>
                        </div>
                        <div className='content'>
                          <div className='d-flex mb-1 align-items-center'>
                            <span className='odometer base'>
                              {' '}
                              <span>60</span>
                            </span>
                            <span className='added base'>+</span>
                          </div>
                          <span className='fs-6 fw-400 ptext2 inter'>
                            Total Award
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-5 col-lg-5'>
              <div className='provide__thumb'>
                <img
                  alt='card'
                  loading='lazy'
                  width='644'
                  height='692'
                  decoding='async'
                  data-nimg='1'
                  className='img-fluid'
                  src={wechooseimg}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Worksection />
      <Contactbanner />
      <ReviewSection />
      <Downloadapp />

      <Footer />
    </>
  )
}

export default About
