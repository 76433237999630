import React from 'react'
import '../Bannerbreadcrump/Bannerbreadcrump.css'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import contactbg from '../../../src/Assets/contactus.webp'
import chock1 from '../../../src/Assets/chok1.webp'
import chock2 from '../../../src/Assets/chok2.webp'
import chock3 from '../../../src/Assets/chok3.webp'
import chock4 from '../../../src/Assets/chok4.webp'
import chock5 from '../../../src/Assets/chok4 (1).webp'

const Bannerbreadcrump = () => {
  return (
    <section className='banner__breadcumn overhid ralt'>
      <div className='breadcumnd__wrapper'>
        <div className='container'>
          <div className='row align-items-center justify-content-between gy-5 gy-md-0'>
            <div className='col-xxl-6 col-xl-6 col-lg-7 col-md-7 col-sm-7'>
              <div className='breadcumnd__content'>
                <span className='d4 mb-5 text-white'>Contact Us</span>
                <ul className='breadcumnd__link flex-wrap gap-1 d-flex align-items-center'>
                  <li className='fs-6 base2 fw-medium inter'>
                    <a className='text-white' href='/'>
                      Home
                    </a>
                  </li>
                  <li>
                    <i className='material-symbols-outlined text-white'>
                      <KeyboardArrowRightIcon />
                    </i>
                  </li>
                  <li className='fs-6 base2 fw-medium inter'>
                    <a className='text-white' href='URL:void(0)'>
                      Pages
                    </a>
                  </li>
                  <li>
                    <i className='material-symbols-outlined text-white'>
                      <KeyboardArrowRightIcon />
                    </i>
                  </li>
                  <li className='fs-6 base2 fw-medium inter'>Contact Us</li>
                </ul>
              </div>
            </div>
            <div className='col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-5'>
              <div className='featured__card text-end undefined'>
                <img
                  alt='img'
                  loading='lazy'
                  width='342'
                  height='279'
                  decoding='async'
                  data-nimg='1'
                  className='img-fluid'
                  src={contactbg}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='element1'>
        <img
          alt='element'
          loading='lazy'
          width='181'
          height='88'
          decoding='async'
          data-nimg='1'
          className='img-fluid'
          src={chock1}
        />
      </div>
      <div className='element2'>
        <img
          alt='element'
          loading='lazy'
          width='186'
          height='126'
          decoding='async'
          data-nimg='1'
          className='img-fluid'
          src={chock2}
        />
      </div>
      <div className='element3'>
        <img
          alt='element'
          loading='lazy'
          width='127'
          height='86'
          decoding='async'
          data-nimg='1'
          className='img-fluid'
          src={chock3}
        />
      </div>
      <div className='element4'>
        <img
          alt='element'
          loading='lazy'
          width='232'
          height='113'
          decoding='async'
          data-nimg='1'
          className='img-fluid'
          src={chock4}
        />
      </div>
      <div className='element5'>
        <img
          alt='element'
          loading='lazy'
          width='127'
          height='86'
          decoding='async'
          data-nimg='1'
          className='img-fluid'
          src={chock5}
        />
      </div>
    </section>
  )
}

export default Bannerbreadcrump
