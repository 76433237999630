import React, { useState, useEffect } from 'react'
import '../Header/Header.css'
import logo from '../../../../src/Assets/logo.png'
import smalllogo from '../../../../src/Assets/small-logo.webp'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import SigninModal from '../SigninModal'
import SignupModal from '../SignupModal/SignupModal'
import { Link } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'
import CalculatorForm from '../CalculatorForm/CalculatorForm'

const Header = () => {
  const [toggle, setToggle] = useState(false)
  const [isScroll, setIsScroll] = useState(false)
  const [searchbtn, setSearchBtn] = useState(false)
  const [hoverMenu, setHoverMenu] = useState(false)
  const [signinmodel, setSigninModel] = useState(false)
  const [signupmodel, setSignupModel] = useState(false)

  const handleSearch = () => {
    setSearchBtn(!searchbtn)
  }

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScroll(true)
      } else {
        setIsScroll(false)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleToggle = () => {
    setToggle(!toggle)
  }

  return (
    <header
      className={`header-section ${
        isScroll ? 'menu-fixed' : ''
      } animated fadeInDown`}
    >
      <div className='container'>
        <div className='header-wrapper'>
          <div className='logo-menu'>
            <a className='logo' href='/'>
              <img
                alt='logo'
                loading='lazy'
                width='295'
                height='60'
                decoding='async'
                data-nimg='1'
                src={logo}
                style={{ color: 'transparent' }}
              />
            </a>
            <a className='small__logo d-xl-none' href='/'>
              <img
                alt='logo'
                loading='lazy'
                width='60'
                height='60'
                decoding='async'
                data-nimg='1'
                src={smalllogo}
                style={{ color: 'transparent' }}
              />
            </a>
          </div>
          <ul className={toggle ? 'main-menu active' : 'main-menu'}>
            <li>
              <Link to='/' className='navbar-active'>
                Home
              </Link>
            </li>
            <li>
              <Link to='/about' className=''>
                About us
              </Link>
            </li>
            <li
              onMouseEnter={() => setHoverMenu(true)}
              className='financial-product-menu'
            >
              <Link to='/'>
                Financial Product<i className='fas fa-chevron-down'></i>
              </Link>
            </li>
            <div
              className={
                hoverMenu ? 'product-menu active' : 'product-menu d-none'
              }
              onMouseLeave={() => setHoverMenu(false)}
            >
              <div className='row'>
                <div className='col-lg-6'>
                  <h3>Resources</h3>
                  <ul className='sub-menu'>
                    <li>
                      <Link to='/calculator-detail'>SIP Calculator</Link>
                    </li>
                    <li>
                      <Link to='/calculator-detail'>GST Calculator</Link>
                    </li>
                    <li>
                      <Link to='/calculator-detail'>FD Calculator</Link>
                    </li>
                    <li>
                      <Link to='/calculator-detail'>PPF Calculator</Link>
                    </li>
                    <li>
                      <Link to='/calculator-detail'>IFSC Code</Link>
                    </li>
                    <li>
                      <Link to='/calculator-detail'>
                        Personal Loan EMI Calculator
                      </Link>
                    </li>
                    <li>
                      <Link to='/calculator-detail'>
                        Home Loan EMI Calculator
                      </Link>
                    </li>
                    <li>
                      <Link to='/calculator-detail'>PPF Calculator</Link>
                    </li>
                  </ul>
                </div>
                <div className='col-lg-6'>
                  <h3 className='mt-3 mt-md-0'>Resources</h3>
                  <ul className='sub-menu'>
                    <li>
                      <Link to='/calculator-detail'>SIP Calculator</Link>
                    </li>
                    <li>
                      <Link to='/calculator-detail'>GST Calculator</Link>
                    </li>
                    <li>
                      <Link to='/calculator-detail'>FD Calculator</Link>
                    </li>
                    <li>
                      <Link to='/calculator-detail'>PPF Calculator</Link>
                    </li>
                    <li>
                      <Link to='/calculator-detail'>IFSC Code</Link>
                    </li>
                    <li>
                      <Link to='/calculator-detail'>
                        Personal Loan EMI Calculator
                      </Link>
                    </li>
                    <li>
                      <Link to='/calculator-detail'>
                        Home Loan EMI Calculator
                      </Link>
                    </li>
                    <li>
                      <Link to='/calculator-detail'>PPF Calculator</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <li>
              <Link to='/contact'>Contact us</Link>
            </li>
          </ul>
          <div className='menu__right__components d-flex align-items-center'>
            <div className='menu__components'>
              <button aria-label='Submit' id='searchBtn' onClick={handleSearch}>
                <SearchOutlinedIcon />
              </button>
              <Link
                to=''
                className='cmn--btn'
                style={{ cursor: 'pointer' }}
                onClick={() => setSigninModel(true)}
              >
                <span>Sign-in</span>
              </Link>
            </div>
            <div className='header-bar d-lg-none' onClick={handleToggle}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
      {signinmodel && (
        <SigninModal
          show={signinmodel}
          setModel={setSigninModel}
          setShowSignup={setSignupModel}
        />
      )}
      {signupmodel && (
        <SignupModal show={signupmodel} setShowSignup={setSignupModel} />
      )}

      <div
        id='searchPopup'
        aria-hidden='true'
        className={searchbtn ? 'search__popup open' : 'search__popup'}
      >
        <form className='popup-content d-flex align-items-center'>
          <input placeholder='Search Here' type='text' />
          <button aria-label='close' id='closeButton'>
            <i className='material-symbols-outlined'>
              <CloseIcon className='text-white fs-2' />
            </i>
          </button>
        </form>
      </div>
    </header>
  )
}

export default Header
