import React from 'react'
import '../Contact/Contact.css'
import Header from '../Common/Header/Header'

import Select from 'react-select'
import Footer from '../Common/Footer/Footer'
import Bannerbreadcrump from '../Bannerbreadcrump/Bannerbreadcrump'

const countries = [
  { value: 'usa', label: 'USA' },
  { value: 'canada', label: 'Canada' },
  { value: 'uk', label: 'UK' },
  { value: 'australia', label: 'Australia' }
  // Add more countries as needed
]

const Contact = () => {
  return (
    <>
      <Header />
      <Bannerbreadcrump />
      <section className='contact__section'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6'>
              <div className='search__content'>
                <div className='section__title text-center '>
                  <h2 className='title wow fadeInDown'>
                    Get in touch with us.
                  </h2>
                  <p className='ptext2 fs-6 fw-400 inter wow fadeInUp'>
                    Fill up the form and our team will get back to you within 24
                    hours
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-lg-8'>
              <div className='contact__wrapper  bg-white'>
                <form className='write__review'>
                  <div className='row g-4 justify-content-start'>
                    <div className='col-lg-6 col-md-6'>
                      <div className='frm__grp'>
                        <label
                          htmlFor='name'
                          className='fs-6 mb-2 fw-500 inter title'
                        >
                          Name
                        </label>
                        <input
                          id='name'
                          placeholder='Enter Your Name...'
                          required=''
                          type='text'
                        />
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-6'>
                      <div className='frm__grp'>
                        <label
                          htmlFor='enamee'
                          className='fs-6 mb-2 fw-500 inter title '
                        >
                          Email
                        </label>
                        <input
                          id='enamee'
                          placeholder='Enter Your Email...'
                          required=''
                          type='email'
                        />
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-6'>
                      <div className='frm__grp'>
                        <label
                          htmlFor='numbers'
                          className='fs-6 mb-2 fw-500 inter title '
                        >
                          Phone
                        </label>
                        <input
                          id='numbers'
                          placeholder='Enter Phone Number'
                          required=''
                          type='text'
                        />
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-6'>
                      <div className='frm__grp'>
                        <label className='fs-6 mb-2 fw-500 inter title '>
                          Country
                        </label>
                        <Select
                          options={countries}
                          className='selector'
                          placeholder='Select Country'
                          components={{
                            IndicatorSeparator: () => null
                          }}
                        />
                      </div>
                    </div>
                    <div className='col-lg-12'>
                      <div className='frm__grp'>
                        <label className='fs-6 mb-2 fw-500 inter title '>
                          Message
                        </label>
                        <textarea
                          name='writecommnet'
                          cols='30'
                          rows='5'
                          placeholder='Enter Your Message...'
                        ></textarea>
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='frm__grp'>
                        <button
                          aria-label='Submit'
                          type='submit'
                          className='cmn--btn'
                        >
                          <span>Send Message</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default Contact
