import React from 'react'
import '../Contactbanner/Contactbanner.css'
import chock1 from '../../../src/Assets/chok1.webp'
import chock2 from '../../../src/Assets/chok2.webp'
import chock3 from '../../../src/Assets/chok3.webp'
import chock4 from '../../../src/Assets/chok4.webp'
import chock5 from '../../../src/Assets/chok4 (1).webp'
import contactcard from '../../../src/Assets/call-card.webp'
import { Link } from 'react-router-dom'

const Contactbanner = () => {
  return (
    <section class='call__section round16 ralt mb-5'>
      <div class='container'>
        <div class='call__action'>
          <div class='row g-4 align-items-center justify-content-between'>
            <div class='col-xxl-5 col-xl-6 col-lg-6'>
              <div class='call__card'>
                <img
                  alt='img'
                  loading='lazy'
                  width='526'
                  height='362'
                  decoding='async'
                  data-nimg='1'
                  class='img-fluid'
                  src={contactcard}
                />
              </div>
            </div>
            <div class='col-xxl-6 col-xl-6 col-lg-6'>
              <div class='call__contetn'>
                <div class='section__title'>
                  <h4
                    class='sub ralt base2 mb-3 wow fadeInUp'
                    data-wow-duration='0.5s'
                  >
                    Call To Action
                  </h4>
                  <h2
                    class='text-white mb-4 wow fadeInUp'
                    data-wow-duration='0.7s'
                  >
                    Find and purchase the perfect Credit Card on Our Marketplace
                  </h2>
                  <p
                    class='text-white mb-4 fs-6 fw-400 inter wow fadeInUp'
                    data-wow-duration='0.9s'
                  >
                    A call to action (CTA) is a powerful tool to encourage your
                    website visitors to take action, such as signing up for a
                    service, making a purchase to a newsletter.
                  </p>
                  <Link to='/contact' class='cmn--btn'>
                    <span>Contact Us</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class='element1'>
        <img
          alt='element'
          loading='lazy'
          width='181'
          height='88'
          decoding='async'
          data-nimg='1'
          class='img-fluid'
          src={chock1}
        />
      </div>
      <div class='element2'>
        <img
          alt='element'
          loading='lazy'
          width='186'
          height='126'
          decoding='async'
          data-nimg='1'
          class='img-fluid'
          src={chock2}
        />
      </div>
      <div class='element3'>
        <img
          alt='element'
          loading='lazy'
          width='127'
          height='86'
          decoding='async'
          data-nimg='1'
          class='img-fluid'
          src={chock3}
        />
      </div>
      <div class='element4'>
        <img
          alt='element'
          loading='lazy'
          width='232'
          height='113'
          decoding='async'
          data-nimg='1'
          class='img-fluid'
          src={chock4}
        />
      </div>
      <div class='element5'>
        <img
          alt='element'
          loading='lazy'
          width='186'
          height='126'
          decoding='async'
          data-nimg='1'
          class='img-fluid'
          src={chock5}
        />
      </div>
    </section>
  )
}

export default Contactbanner
