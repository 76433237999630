import React from 'react'
import '../Worksection/Worksection.css'
import TravelExploreIcon from '@mui/icons-material/TravelExplore'
import TouchAppIcon from '@mui/icons-material/TouchApp'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import TaskAltIcon from '@mui/icons-material/TaskAlt'

const Worksection = () => {
  return (
    <section class='how__worksection2'>
      <div class='container'>
        <div class='row justify-content-center'>
          <div class='col-lg-6'>
            <div class='section__title text-center mb-60'>
              <h4
                class='sub ralt base mb-16 wow fadeInUp'
                data-wow-duration='0.5s'
              >
                How It's Work
              </h4>
              <h2 class='title mb-24 wow fadeInUp' data-wow-duration='0.7s'>
                Get ready to buy our unique credit card
              </h2>
              <p
                class='ptext2 fs-6 fw-400 inter wow fadeInUp'
                data-wow-duration='0.9s'
              >
                Buying a credit card from a credit card marketplace website
                involves browsing, comparing, selecting
              </p>
            </div>
          </div>
        </div>
        <div class='row g-4 justify-content-center'>
          <div
            class='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 wow fadeInDown'
            data-wow-duration='0.5s'
          >
            <div class='howwork__item transition text-center'>
              <div class='work__icon arrow1 d-flex align-items-center justify-content-center m-auto ralt  shadow1'>
                <span class='badge basebg d-flex align-items-center justify-content-center fz-18 fw-600 text-white inter '>
                  01
                </span>
                <i class='material-symbols-outlined base  basebord'>
                  <TravelExploreIcon className='card_icon' />
                </i>
              </div>
              <div class='content mt-4'>
                <h4 class='title mb-3'>
                  <a href='/compare-card'>Search and Compare</a>
                </h4>
                <p class='fs-6 fw-400 inter title'>
                  First, you'll need to search and compare credit cards offered
                  on the credit card marketplace website
                </p>
              </div>
            </div>
          </div>
          <div
            class='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 wow fadeInDown'
            data-wow-duration='0.5s'
          >
            <div class='howwork__item transition text-center'>
              <div class='work__icon arrow2 d-flex align-items-center justify-content-center m-auto ralt  shadow1'>
                <span class='badge base4bg d-flex align-items-center justify-content-center fz-18 fw-600 text-white inter '>
                  02
                </span>
                <i class='material-symbols-outlined base4  base4bord'>
                  <TouchAppIcon className='card_icon' />
                </i>
              </div>
              <div class='content mt-4'>
                <h4 class='title mb-3'>
                  <a href='/compare-card'>Select a Card</a>
                </h4>
                <p class='fs-6 fw-400 inter title'>
                  First, you'll need to search and compare credit cards offered
                  on the credit card marketplace website
                </p>
              </div>
            </div>
          </div>
          <div
            class='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 wow fadeInDown'
            data-wow-duration='0.5s'
          >
            <div class='howwork__item transition text-center'>
              <div class='work__icon arrow3 d-flex align-items-center justify-content-center m-auto ralt  shadow1'>
                <span class='badge base5bg d-flex align-items-center justify-content-center fz-18 fw-600 text-white inter '>
                  03
                </span>
                <i class='material-symbols-outlined base5  base5bord'>
                  <ReceiptLongIcon className='card_icon' />
                </i>
              </div>
              <div class='content mt-4'>
                <h4 class='title mb-3'>
                  <a href='/compare-card'>Apply for the Card</a>
                </h4>
                <p class='fs-6 fw-400 inter title'>
                  First, you'll need to search and compare credit cards offered
                  on the credit card marketplace website
                </p>
              </div>
            </div>
          </div>
          <div
            class='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 wow fadeInDown'
            data-wow-duration='0.5s'
          >
            <div class='howwork__item transition text-center'>
              <div class='work__icon d-flex align-items-center justify-content-center m-auto ralt  shadow1'>
                <span class='badge basebg d-flex align-items-center justify-content-center fz-18 fw-600 text-white inter '>
                  04
                </span>
                <i class='material-symbols-outlined base  basebord'>
                  <TaskAltIcon className='card_icon' />
                </i>
              </div>
              <div class='content mt-4'>
                <h4 class='title mb-3'>
                  <a href='/compare-card'>Get Approved</a>
                </h4>
                <p class='fs-6 fw-400 inter title'>
                  First, you'll need to search and compare credit cards offered
                  on the credit card marketplace website
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Worksection
