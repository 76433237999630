import React from 'react'
import { Modal, ModalBody } from 'reactstrap'
import '../../../src/Components/Common/SigninModal.css'
import signinimg from '../../../src/Assets/signup-thumb.webp'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { Link } from '@mui/material'

function SigninModal ({ show, setModel, setShowSignup }) {
  const toggleSigninModel = () => setModel(!show)

  const openSignupModal = () => {
    setModel(false)
    setShowSignup(true)
  }

  return (
    <Modal
      isOpen={show}
      toggle={toggleSigninModel}
      centered
      size='lg'
      backdrop='static'
      keyboard={true}
    >
      <ModalBody>
        <div className=''>
          <div className='gsc_modalWrapper'>
            <div onClick={toggleSigninModel} className='v2_close false'>
              <a href='#' className='close-button'>
                <div className='in'>
                  <div className='close-button-block'></div>
                  <div className='close-button-block'></div>
                </div>
                <div className='out'>
                  <div className='close-button-block'></div>
                  <div className='close-button-block'></div>
                </div>
              </a>
            </div>
            <div className='signinmodal_box'>
              <div className='row justify-content-center align-items-center'>
                <div className='col-lg-6'>
                  <div className='signup__boxes round16'>
                    <h3 className='title mb-4'>Welcome Back!</h3>
                    <p className='fz-16 title fw-400 inter mb-3'>
                      Sign in to your account and join us
                    </p>
                    <form action='#0' className='write__review'>
                      <div className='row g-4'>
                        <div className='col-lg-12'>
                          <div className='frm__grp'>
                            <label
                              htmlFor='enamee'
                              className='fz-18 fw-500 inter title mb-2'
                            >
                              Enter Your Email ID
                            </label>
                            <input
                              id='enamee'
                              placeholder='Enter Your Email...'
                              required=''
                              type='text'
                            />
                          </div>
                        </div>
                        <div className='col-lg-12'>
                          <div className='frm__grp inputPassword'>
                            <label
                              htmlFor='pas'
                              className='fz-18 fw-500 inter title mb-2'
                            >
                              Enter Your Password
                            </label>
                            <input
                              id='pas'
                              placeholder='Enter Your Password...'
                              required=''
                              type='password'
                            />
                            <div className='toggle-icon'>
                              <VisibilityOffIcon />
                            </div>
                          </div>
                        </div>
                        <p className='fs-6 fw-400 title inter'>
                          Do you have an account?{' '}
                          <Link
                            to=''
                            className='base'
                            onClick={openSignupModal}
                          >
                            Signup
                          </Link>
                        </p>
                        <div className='col-lg-6'>
                          <div className='frm__grp'>
                            <button
                              aria-label='Sign in'
                              type='submit'
                              className='cmn--btn'
                            >
                              <span>Sign In</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className='col-lg-6'>
                  <div className='signup__thumb'>
                    <img
                      alt='img'
                      loading='lazy'
                      width='784'
                      height='636'
                      decoding='async'
                      data-nimg='1'
                      className='img-fluid'
                      src={signinimg}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default SigninModal
