import React from 'react'
import Banner from '../Banner/Banner'
import Header from '../Common/Header/Header'
import Card from '../Card/Card'
import Insurance from '../InsuranceCard/Insurance'
import CardProduct from '../CardProduct/CardProduct'
import Brand from '../Brand/Brand'
import Footer from '../Common/Footer/Footer'
import Testimonial from '../Testimonial/Testimonial'

const Home = () => {
  return (
    <>
      <Header />
      <Banner />
      <Card />
      <Insurance />
      <CardProduct />
      <Brand />
      <Testimonial />
      <Footer />
    </>
  )
}

export default Home
