import React from 'react'
import '../CardProduct/CardProduct.css'
import productcard from '../../../src/Assets/choose-card.webp'
import map from '../../../src/Assets/map.webp'
import balance from '../../../src/Assets/balance.svg'
import access from '../../../src/Assets/access.svg'
import secure from '../../../src/Assets/secure.svg'
import customer from '../../../src/Assets/cutomer.svg'

const CardProduct = () => {
  return (
    <section class='choose__section ralt bgadd'>
      <div class='container'>
        <div class='row g-4 align-items-center'>
          <div class='col-xl-6 col-lg-6'>
            <div class='choose__thumb ralt'>
              <img
                alt='card'
                loading='lazy'
                width='526'
                height='559'
                decoding='async'
                data-nimg='1'
                class='choose__mthumb w-100 h-100'
                src={productcard}
              />
              <img
                alt='map-img'
                loading='lazy'
                width='873'
                height='540'
                decoding='async'
                data-nimg='1'
                class='mapthumb'
                src={map}
              />
            </div>
          </div>
          <div class='col-xl-6 col-lg-6'>
            <div class='choose__content'>
              <div class='section__title mb-40'>
                <h4
                  class='sub ralt base mb-3 wow fadeInUp'
                  data-wow-duration='1.1s'
                >
                  Choose Personal Credit Product
                </h4>
                <h2 class='title mb-4 wow fadeInUp' data-wow-duration='1.2s'>
                  The Benefits of Our Credit Card Marketplace
                </h2>
                <p
                  class='ptext2 fz-16 fw-400 inter wow fadeInUp'
                  data-wow-duration='1.4s'
                >
                  Looking for the perfect credit card can be time-consuming and
                  overwhelming, which is why our credit card marketplace is here
                  to help
                </p>
              </div>
              <ul class='choose__listwrap'>
                <li class='d-flex wow fadeInUp' data-wow-duration='1.7s'>
                  <i class='material-symbols-outlined base ifz32 bord round50 d-flex  justify-content-center'>
                    <img src={balance} />
                  </i>
                  <span class='contentbox'>
                    <span class=' d-block mb-1 fw-600 inter title'>
                      Wide Choice
                    </span>
                    <span class='ptext2 fz-16 fw-400 inter'>
                      Your creditcard marktplace has a reputation for being
                      trustworthy
                    </span>
                  </span>
                </li>
                <li class='d-flex wow fadeInUp' data-wow-duration='1.9s'>
                  <i class='material-symbols-outlined base2 ifz32 bord2  d-flex  justify-content-center'>
                    <img src={access} />
                  </i>
                  <span class='contentbox'>
                    <span class=' d-block mb-1 fw-600 inter title'>
                      Easy Access to Credit
                    </span>
                    <span class='ptext2 fz-16 fw-400 inter'>
                      Your creditcard marktplace has a reputation for being
                      trustworthy
                    </span>
                  </span>
                </li>
                <li class='d-flex wow fadeInUp' data-wow-duration='1.9s'>
                  <i class='material-symbols-outlined base2 ifz32 bord3  d-flex  justify-content-center'>
                    <img src={secure} />
                  </i>
                  <span class='contentbox'>
                    <span class=' d-block mb-1 fw-600 inter title'>
                      Easy Access to Credit
                    </span>
                    <span class='ptext2 fz-16 fw-400 inter'>
                      Your creditcard marktplace has a reputation for being
                      trustworthy
                    </span>
                  </span>
                </li>
                <li class='d-flex wow fadeInUp' data-wow-duration='1.9s'>
                  <i class='material-symbols-outlined base2 ifz32 bord4  d-flex  justify-content-center'>
                    <img src={customer} />
                  </i>
                  <span class='contentbox'>
                    <span class=' d-block mb-1 fw-600 inter title'>
                      Easy Access to Credit
                    </span>
                    <span class='ptext2 fz-16 fw-400 inter'>
                      Your creditcard marktplace has a reputation for being
                      trustworthy
                    </span>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CardProduct
