import React from 'react'
import '../Calculator/Calculator.css'
import Header from '../Common/Header/Header'
import Bannerbreadcrump from '../Bannerbreadcrump/Bannerbreadcrump'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import sip from '../../../src/Assets/SIP.png'
import lumpsum from '../../../src/Assets/Lumpsum.png'
import swp from '../../../src/Assets/SWP.png'
import mf from '../../../src/Assets/MF.png'
import { Link } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const Calculator = () => {
  const navigate = useNavigate()
  return (
    <>
      <Header />
      <Bannerbreadcrump />
      <section className='calculator_category'>
        <div className='container'>
          <div class='d-flex mb-md-5 mb-3 gap-3 flex-wrap justify-content-between align-items-center'>
            <span class='fs-6 fw-400 ptext inter'>Showing 9 of 60 Results</span>
            <div class='select__sort d-flex align-items-center gap-2'>
              <span class='fs-6 fw-400 inter ptext'>Sort By :</span>
              <div class='selector'>
                <button
                  id='headlessui-listbox-button-:r6:'
                  type='button'
                  aria-haspopup='listbox'
                  aria-expanded='false'
                  data-headlessui-state=''
                >
                  <span class='font-normal'>All Categories</span>
                </button>
              </div>
            </div>
          </div>

          <div className='top__ratedwrapper'>
            <div className='row g-3'>
              <div className='col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-6'>
                <div
                  className='top__items suctom__space bgwhite round16 bgwhite'
                  tabindex='-1'
                >
                  <div className='icon mx-auto d-flex align-items-center justify-content-center boxes2 round50'>
                    <i className='material-symbols-outlined icolor1'>
                      <img src={sip} />
                    </i>
                  </div>
                  <div className='content text-center mt-24'>
                    <h3 className='mb-16 base'>SIP</h3>
                    <p className='fs-6 fw-400 inter ptext2 mb-3'>
                      Avail Cashless Treatment Anywhere
                    </p>
                    <span
                      className='arrow m-auto boxes4 round50 d-flex align-items-center justify-content-center'
                      style={{ cursor: 'pointer' }}
                    >
                      <ChevronRightIcon
                        onClick={() => navigate('/calculator-detail')}
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className='col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-6'>
                <div
                  className='top__items suctom__space bgwhite round16 bgwhite'
                  tabindex='-1'
                >
                  <div className='icon mx-auto d-flex align-items-center justify-content-center boxes2 round50'>
                    <i className='material-symbols-outlined icolor1'>
                      <img src={lumpsum} />
                    </i>
                  </div>
                  <div className='content text-center mt-24'>
                    <h3 className='mb-16 base'>Lumpsum</h3>
                    <p className='fs-6 fw-400 inter ptext2 mb-3'>
                      Buy Two Wheeler Insurance Real Fast
                    </p>
                    <span
                      className='arrow m-auto boxes4 round50 d-flex align-items-center justify-content-center'
                      style={{ cursor: 'pointer' }}
                    >
                      <ChevronRightIcon
                        onClick={() => navigate('/calculator-detail')}
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className='col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-6'>
                <div
                  className='top__items suctom__space bgwhite round16 bgwhite'
                  tabindex='-1'
                >
                  <div className='icon mx-auto d-flex align-items-center justify-content-center boxes2 round50'>
                    <i className='material-symbols-outlined icolor1'>
                      <img src={swp} />
                    </i>
                  </div>
                  <div className='content text-center mt-24'>
                    <h3 className='mb-16 base'>SWP</h3>
                    <p className='fs-6 fw-400 inter ptext2 mb-3'>
                      Irresistible Deals On Your Car Insurance
                    </p>
                    <span
                      className='arrow m-auto boxes4 round50 d-flex align-items-center justify-content-center'
                      style={{ cursor: 'pointer' }}
                      href='/gift-card'
                    >
                      <ChevronRightIcon
                        onClick={() => navigate('/calculator-detail')}
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className='col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-6'>
                <div
                  className='top__items suctom__space bgwhite round16 bgwhite'
                  tabindex='-1'
                >
                  <div className='icon mx-auto d-flex align-items-center justify-content-center boxes2 round50'>
                    <i className='material-symbols-outlined icolor1'>
                      <img src={swp} />
                    </i>
                  </div>
                  <div className='content text-center mt-24'>
                    <h3 className='mb-16 base'>SWP</h3>
                    <p className='fs-6 fw-400 inter ptext2 mb-3'>
                      Irresistible Deals On Your Car Insurance
                    </p>
                    <span
                      className='arrow m-auto boxes4 round50 d-flex align-items-center justify-content-center'
                      style={{ cursor: 'pointer' }}
                      href='/gift-card'
                    >
                      <ChevronRightIcon
                        onClick={() => navigate('/calculator-detail')}
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className='col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-6'>
                <div
                  className='top__items suctom__space bgwhite round16 bgwhite'
                  tabindex='-1'
                >
                  <div className='icon mx-auto d-flex align-items-center justify-content-center boxes2 round50'>
                    <i className='material-symbols-outlined icolor1'>
                      <img src={swp} />
                    </i>
                  </div>
                  <div className='content text-center mt-24'>
                    <h3 className='mb-16 base'>SWP</h3>
                    <p className='fs-6 fw-400 inter ptext2 mb-3'>
                      Irresistible Deals On Your Car Insurance
                    </p>
                    <span
                      className='arrow m-auto boxes4 round50 d-flex align-items-center justify-content-center'
                      style={{ cursor: 'pointer' }}
                      href='/gift-card'
                    >
                      <ChevronRightIcon
                        onClick={() => navigate('/calculator-detail')}
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className='col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-6'>
                <div
                  className='top__items suctom__space bgwhite round16 bgwhite'
                  tabindex='-1'
                >
                  <div className='icon mx-auto d-flex align-items-center justify-content-center boxes2 round50'>
                    <i className='material-symbols-outlined icolor1'>
                      <img src={swp} />
                    </i>
                  </div>
                  <div className='content text-center mt-24'>
                    <h3 className='mb-16 base'>SWP</h3>
                    <p className='fs-6 fw-400 inter ptext2 mb-3'>
                      Irresistible Deals On Your Car Insurance
                    </p>
                    <span
                      className='arrow m-auto boxes4 round50 d-flex align-items-center justify-content-center'
                      style={{ cursor: 'pointer' }}
                      href='/gift-card'
                    >
                      <ChevronRightIcon
                        onClick={() => navigate('/calculator-detail')}
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className='col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-6'>
                <div
                  className='top__items suctom__space bgwhite round16 bgwhite'
                  tabindex='-1'
                >
                  <div className='icon mx-auto d-flex align-items-center justify-content-center boxes2 round50'>
                    <i className='material-symbols-outlined icolor1'>
                      <img src={swp} />
                    </i>
                  </div>
                  <div className='content text-center mt-24'>
                    <h3 className='mb-16 base'>SWP</h3>
                    <p className='fs-6 fw-400 inter ptext2 mb-3'>
                      Irresistible Deals On Your Car Insurance
                    </p>
                    <span
                      className='arrow m-auto boxes4 round50 d-flex align-items-center justify-content-center'
                      style={{ cursor: 'pointer' }}
                      href='/gift-card'
                    >
                      <ChevronRightIcon
                        onClick={() => navigate('/calculator-detail')}
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className='col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-6'>
                <div
                  className='top__items suctom__space bgwhite round16 bgwhite'
                  tabindex='-1'
                >
                  <div className='icon mx-auto d-flex align-items-center justify-content-center boxes2 round50'>
                    <i className='material-symbols-outlined icolor1'>
                      <img src={swp} />
                    </i>
                  </div>
                  <div className='content text-center mt-24'>
                    <h3 className='mb-16 base'>SWP</h3>
                    <p className='fs-6 fw-400 inter ptext2 mb-3'>
                      Irresistible Deals On Your Car Insurance
                    </p>
                    <span
                      className='arrow m-auto boxes4 round50 d-flex align-items-center justify-content-center'
                      style={{ cursor: 'pointer' }}
                      href='/gift-card'
                    >
                      <ChevronRightIcon
                        onClick={() => navigate('/calculator-detail')}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
    // <section className='calculator section' id='calculator'>
    //   <div className='container'>
    //     <div className='row gy-5 gy-xl-0 justify-content-between align-items-center'>
    //       <div className='col-12 col-lg-6 col-xxl-5 mx-auto mx-lg-0'>
    //         <div className='calculator-input'>
    //           <div
    //             className={
    //               calculator
    //                 ? 'card card--custom calc calc_hide'
    //                 : 'card card--custom calc'
    //             }
    //           >
    //             <h2
    //               className='title mb-3 wow fadeInDown text-start'
    //               data-wow-duration='0.8s'
    //               style={{
    //                 visibility: 'visible',
    //                 animationDuration: '0.8s',
    //                 animationName: 'fadeInLeft'
    //               }}
    //             >
    //               Calculator
    //             </h2>
    //             <div className='card--custom__loan'>
    //               <div className='card--custom__form'>
    //                 <form
    //                   method='POST'
    //                   autoComplete='off'
    //                   id='frmCalculate'
    //                   className='calculate__form'
    //                 >
    //                   <div className='row gy-5 gy-md-0'>
    //                     <div className='col-12 col-md-6'>
    //                       <h5 className='calculator__title'>Loan 01</h5>
    //                       <div className='calculate__form-part'>
    //                         <div className='input-single'>
    //                           <label className='label' htmlFor='amount'>
    //                             Loan Amount <span>(required)</span>
    //                           </label>
    //                           <input
    //                             type='number'
    //                             className='form-control'
    //                             name='amount'
    //                             id='amount'
    //                             placeholder='$0'
    //                             required
    //                           />
    //                         </div>
    //                         <div className='input-single'>
    //                           <label className='label' htmlFor='year'>
    //                             Loan Term <span>(years)</span>
    //                           </label>
    //                           <input
    //                             type='number'
    //                             className='form-control'
    //                             name='year'
    //                             id='year'
    //                             placeholder='0 years'
    //                           />
    //                         </div>
    //                         <div className='input-single'>
    //                           <label className='label' htmlFor='interest'>
    //                             Interest Rate (%) <span>(required)</span>
    //                           </label>
    //                           <input
    //                             type='number'
    //                             className='form-control'
    //                             name='interest'
    //                             id='interest'
    //                             placeholder='0.00%'
    //                           />
    //                         </div>
    //                       </div>
    //                     </div>
    //                     <div className='col-12 col-md-6'>
    //                       <h5 className='calculator__title'>Loan 02</h5>
    //                       <div className='calculate__form-part'>
    //                         <div className='input-single'>
    //                           <label className='label' htmlFor='amount2'>
    //                             Loan Amount <span>(required)</span>
    //                           </label>
    //                           <input
    //                             type='number'
    //                             className='form-control'
    //                             name='amount2'
    //                             id='amount2'
    //                             placeholder='$0'
    //                             required
    //                           />
    //                         </div>
    //                         <div className='input-single'>
    //                           <label className='label' htmlFor='year2'>
    //                             Loan Term <span>(years)</span>
    //                           </label>
    //                           <input
    //                             type='number'
    //                             className='form-control'
    //                             name='year2'
    //                             id='year2'
    //                             placeholder='0 years'
    //                           />
    //                         </div>
    //                         <div className='input-single'>
    //                           <label className='label' htmlFor='interest2'>
    //                             Interest Rate (%) <span>(required)</span>
    //                           </label>
    //                           <input
    //                             type='number'
    //                             className='form-control'
    //                             name='interest2'
    //                             id='interest2'
    //                             placeholder='0.00%'
    //                           />
    //                         </div>
    //                       </div>
    //                     </div>
    //                   </div>
    //                   <span id='CalcMsg'></span>
    //                   <button
    //                     type='submit'
    //                     className='cmn--btn mt-4 mt-md-5'
    //                     name='calc_submit'
    //                     id='calc_submit'
    //                     onClick={handleCalculator}
    //                   >
    //                     <span> Read More </span>
    //                   </button>
    //                 </form>
    //               </div>
    //             </div>
    //           </div>
    //           <div
    //             className={
    //               calculator
    //                 ? 'card card--custom calculator-result calc_show'
    //                 : 'card card--custom calculator-result '
    //             }
    //           >
    //             <h3>Result</h3>
    //             <div className='card--custom__loan'>
    //               <div className='card--custom__form'>
    //                 <div className='calculate__form'>
    //                   <div className='row gy-5 gy-md-0'>
    //                     <div className='col-12 col-md-6'>
    //                       <h5 className='calculator__title'>Loan 01</h5>
    //                       <div className='calculate__form-part'>
    //                         <div className='input-single'>
    //                           <label className='label'>Monthly cost </label>
    //                           <p className='headingFour' id='monthly_cost'></p>
    //                         </div>
    //                         <div className='input-single'>
    //                           <label className='label'>Total cost</label>
    //                           <p className='headingFour' id='total_value'></p>
    //                         </div>
    //                       </div>
    //                     </div>
    //                     <div className='col-12 col-md-6 mt-4 mt-md-5 mt-lg-0'>
    //                       <h5 className='calculator__title'>Loan 02</h5>
    //                       <div className='calculate__form-part'>
    //                         <div className='input-single'>
    //                           <label className='label'>Monthly cost </label>
    //                           <p className='headingFour' id='monthly_cost2'></p>
    //                         </div>
    //                         <div className='input-single'>
    //                           <label className='label'>Total cost</label>
    //                           <p className='headingFour' id='total_value2'></p>
    //                         </div>
    //                       </div>
    //                     </div>
    //                     <div className='col-12 mt-4'>
    //                       <p className='note'>
    //                         <span className=''>MORE:</span> Learn about the pros
    //                         and cons of a shorter-term mortgage or how to get
    //                         preapproved for a mortgage.
    //                       </p>
    //                       <Link to='' className='cmn--btn mt-lg-5 mt-4'>
    //                         <span> Back</span>
    //                       </Link>
    //                     </div>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //       <div className='col-12 col-lg-6'>
    //         <div className='choose__content'>
    //           <div className='section__title'>
    //             <h4 className='sub ralt base mb-3 wow fadeInUp'>
    //               Loan Calculators
    //             </h4>
    //             <h2 className='title mb-3 wow fadeInDown'>
    //               Empower Yourself with Accurate Loan Estimates
    //             </h2>
    //             <p className='ptext2 fs-6 fw-400 inter wow fadeInUp'>
    //               Our loan calculators are powerful tools designed to assist you
    //               in making informed financial decisions. Whether you're
    //               planning a major purchase, estimating monthly payments
    //             </p>
    //           </div>
    //         </div>
    //         <ul className='task__listwrap row-gap-2'>
    //           <li
    //             className='d-flex gap-2 align-items-center wow fadeInUp'
    //             data-wow-duration='1.7s'
    //           >
    //             <TaskAltIcon className='base' />
    //             <span className='contentbox fz-20 fw-500 title inter'>
    //               Loan Payment Calculator
    //             </span>
    //           </li>
    //           <li
    //             className='d-flex gap-2 align-items-center wow fadeInUp'
    //             data-wow-duration='1.7s'
    //           >
    //             <TaskAltIcon className='base' />
    //             <span className='contentbox fz-20 fw-500 title inter'>
    //               Amortization Calculator
    //             </span>
    //           </li>
    //           <li
    //             className='d-flex gap-2 align-items-center wow fadeInUp'
    //             data-wow-duration='1.7s'
    //           >
    //             <TaskAltIcon className='base' />
    //             <span className='contentbox fz-20 fw-500 title inter'>
    //               Affordability Calculator
    //             </span>
    //           </li>
    //           <li
    //             className='d-flex gap-2 align-items-center wow fadeInUp'
    //             data-wow-duration='1.7s'
    //           >
    //             <TaskAltIcon className='base' />
    //             <span className='contentbox fz-20 fw-500 title inter'>
    //               Interest Calculator
    //             </span>
    //           </li>
    //         </ul>
    //       </div>
    //     </div>
    //   </div>
    // </section>
  )
}

export default Calculator
