import React from 'react'
import '../Testimonial/Testimonial.css'
import Slider from 'react-slick'
import testimonilaImg from '../../../src/Assets/testimonialimg.webp'
import testimonilClient from '../../../src/Assets/testimonial1.webp'
// import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import StarIcon from '@mui/icons-material/Star'

const Testimonial = () => {
  var testimonialSlider = {
    dots: false,
    nav: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    autoplaySpeed: 2000
  }
  return (
    <section className='testimonial__section'>
      <div className='container'>
        <div className='row g-4 align-items-center justify-content-between'>
          <div className='col-xl-5 col-lg-5 col-md-12'>
            <div className='testimonial__thumb ralt wow fadeInDown'>
              <img
                alt='card'
                loading='lazy'
                width='658'
                height='679'
                decoding='async'
                data-nimg='1'
                className='img-fluid'
                src={testimonilaImg}
              />
            </div>
          </div>
          <div className='col-xl-6 col-lg-7'>
            <div className='testimonial__content'>
              <div className='section__title'>
                <h4 className='sub ralt base  wow fadeInDown'>Testimonial</h4>
                <h2 className='title wow fadeInUp'>
                  Feedback From Our Valued Customers For Marketplace
                </h2>
                <p className='ptext2 fs-6 fw-400 inter wow fadeInDown'>
                  We understand that choosing a credit card can be overwhelming,
                  especially with so many options available. That's why we've
                  created a user-friendly platform
                </p>
              </div>
              <div className='testimonial__slider wow fadeInUp'>
                <Slider {...testimonialSlider}>
                  <div className=''>
                    <div
                    //   data-index='-1'
                    //   tabindex='-1'
                    //   className='slick-slide'
                    //   aria-hidden='true'
                    //   style={{ width: '660px' }}
                    >
                      <div>
                        <div
                          className='testimonial__items ralt bgadd'
                          //   tabindex='-1'
                        >
                          <div className='ratting  d-flex align-items-center gap-2'>
                            <StarIcon />
                            <StarIcon />
                            <StarIcon />
                            <StarIcon />
                            <StarIcon />
                          </div>
                          <p className='ptext3 inter fs-6 fw-400 mb-md-4 mb-3'>
                            Thanks to the credit card marketplace website, I was
                            able to find the perfect credit card to fit my
                            needs. The website was easy to navigate and provided
                            me with all the information
                          </p>
                          <div className='d-flex align-items-center gap-3'>
                            <div className='thumb'>
                              <img
                                alt=''
                                loading='lazy'
                                width='60'
                                height='60'
                                decoding='async'
                                data-nimg='1'
                                src={testimonilClient}
                              />
                            </div>
                            <div className='cont'>
                              <span className='fs-5 fw-bold inter ptext'>
                                Annette Black
                              </span>
                              <span className='fs-6 d-block ptext fw-400 inter'>
                                Canada
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=''>
                    <div
                    //   data-index='-1'
                    //   tabindex='-1'
                    //   className='slick-slide'
                    //   aria-hidden='true'
                    //   style={{ width: '660px' }}
                    >
                      <div>
                        <div
                          className='testimonial__items ralt bgadd'
                          //   tabindex='-1'
                        >
                          <div className='ratting  d-flex align-items-center gap-2'>
                            <StarIcon />
                            <StarIcon />
                            <StarIcon />
                            <StarIcon />
                            <StarIcon />
                          </div>
                          <p className='ptext3 inter fs-6 fw-400 mb-md-4 mb-3'>
                            Thanks to the credit card marketplace website, I was
                            able to find the perfect credit card to fit my
                            needs. The website was easy to navigate and provided
                            me with all the information
                          </p>
                          <div className='d-flex align-items-center gap-3'>
                            <div className='thumb'>
                              <img
                                alt=''
                                loading='lazy'
                                width='60'
                                height='60'
                                decoding='async'
                                data-nimg='1'
                                src={testimonilClient}
                              />
                            </div>
                            <div className='cont'>
                              <span className='fs-5 fw-bold inter ptext'>
                                Annette Black
                              </span>
                              <span className='fs-6 d-block ptext fw-400 inter'>
                                Canada
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=''>
                    <div
                    //   data-index='-1'
                    //   tabindex='-1'
                    //   className='slick-slide'
                    //   aria-hidden='true'
                    //   style={{ width: '660px' }}
                    >
                      <div>
                        <div
                          className='testimonial__items ralt bgadd'
                          //   tabindex='-1'
                        >
                          <div className='ratting  d-flex align-items-center gap-2'>
                            <StarIcon />
                            <StarIcon />
                            <StarIcon />
                            <StarIcon />
                            <StarIcon />
                          </div>
                          <p className='ptext3 inter fs-6 fw-400 mb-md-4 mb-3'>
                            Thanks to the credit card marketplace website, I was
                            able to find the perfect credit card to fit my
                            needs. The website was easy to navigate and provided
                            me with all the information
                          </p>
                          <div className='d-flex align-items-center gap-3'>
                            <div className='thumb'>
                              <img
                                alt=''
                                loading='lazy'
                                width='60'
                                height='60'
                                decoding='async'
                                data-nimg='1'
                                src={testimonilClient}
                              />
                            </div>
                            <div className='cont'>
                              <span className='fs-5 fw-bold inter ptext'>
                                Annette Black
                              </span>
                              <span className='fs-6 d-block ptext fw-400 inter'>
                                Canada
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=''>
                    <div
                    //   data-index='-1'
                    //   tabindex='-1'
                    //   className='slick-slide'
                    //   aria-hidden='true'
                    //   style={{ width: '660px' }}
                    >
                      <div>
                        <div
                          className='testimonial__items ralt bgadd'
                          //   tabindex='-1'
                        >
                          <div className='ratting  d-flex align-items-center gap-2'>
                            <StarIcon />
                            <StarIcon />
                            <StarIcon />
                            <StarIcon />
                            <StarIcon />
                          </div>
                          <p className='ptext3 inter fs-6 fw-400 mb-md-4 mb-3'>
                            Thanks to the credit card marketplace website, I was
                            able to find the perfect credit card to fit my
                            needs. The website was easy to navigate and provided
                            me with all the information
                          </p>
                          <div className='d-flex align-items-center gap-3'>
                            <div className='thumb'>
                              <img
                                alt=''
                                loading='lazy'
                                width='60'
                                height='60'
                                decoding='async'
                                data-nimg='1'
                                src={testimonilClient}
                              />
                            </div>
                            <div className='cont'>
                              <span className='fs-5 fw-bold inter ptext'>
                                Annette Black
                              </span>
                              <span className='fs-6 d-block ptext fw-400 inter'>
                                Canada
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Testimonial
