import React from 'react'
import '../Brand/Brand.css'
import bajaj from '../../../src/Assets/bajaj.png'
import kotak from '../../../src/Assets/kotak.png'
import adityabirla from '../../../src/Assets/Aditya-Birla.png'
import hdfc from '../../../src/Assets/hdfc.png'
import federal from '../../../src/Assets/federal.png'

const Brand = () => {
  return (
    <section class='popular__card bgadd ralt'>
      <div class='container'>
        <div class='row justify-content-center mb-40'>
          <div class='col-lg-6 col-md-9'>
            <div class='section__title text-center'>
              <h4 class='sub ralt base mb-16 wow fadeInDown'>
                Most Popular Cards
              </h4>
              <h2 class='title wow fadeInUp'>
                Top Credit Cards on Our Marketplace
              </h2>
            </div>
          </div>
        </div>
        <div class='popular__tabs'>
          <div class='nav nav-tabs mb-60' id='nav-tab' role='tablist'>
            <button
              aria-label='nav Link'
              class='nav-link active'
              id='nav-home-tab'
              data-bs-toggle='tab'
              data-bs-target='#nav-home'
              type='button'
              role='tab'
              aria-controls='nav-home'
              aria-selected='true'
            >
              <span class='icon'>
                {/* <i class='material-symbols-outlined'>new_releases</i> */}
              </span>
              <span>All</span>
            </button>
            <button
              aria-label='nav Link'
              class='nav-link'
              id='nav-profile-tab'
              data-bs-toggle='tab'
              data-bs-target='#nav-profile'
              type='button'
              role='tab'
              aria-controls='nav-profile'
              aria-selected='false'
            >
              <span class='icon'>
                {/* <i class='material-symbols-outlined'>bar_chart_4_bars</i> */}
              </span>
              <span>Credit Card</span>
            </button>
            <button
              aria-label='nav Link'
              class='nav-link'
              id='nav-contact-tab'
              data-bs-toggle='tab'
              data-bs-target='#nav-contact'
              type='button'
              role='tab'
              aria-controls='nav-contact'
              aria-selected='false'
            >
              <span class='icon'>
                {/* <i class='material-symbols-outlined'>local_atm</i> */}
              </span>
              <span>Cash Back</span>
            </button>
            <button
              aria-label='nav Link'
              class='nav-link'
              id='nav-contact-tab1'
              data-bs-toggle='tab'
              data-bs-target='#nav-contact1'
              type='button'
              role='tab'
              aria-controls='nav-contact1'
              aria-selected='false'
            >
              <span class='icon'>
                {/* <i class='material-symbols-outlined'>bluetooth_drive</i> */}
              </span>
              <span>Home Loan</span>
            </button>
          </div>
          <div class='tab-content' id='nav-tabContent'>
            <div
              class='tab-pane fade show active'
              id='nav-home'
              role='tabpanel'
              aria-labelledby='nav-home-tab'
            >
              <div className='row g-lg-4 g-md-4 g-2'>
                <div className='col-xl-2 col-lg-3  col-md-4 col-sm-4 col-6 col-6'>
                  <div className='popular__items'>
                    <img src={bajaj} />
                  </div>
                </div>
                <div className='col-xl-2 col-lg-3  col-md-4 col-sm-4 col-6 col-6'>
                  <div className='popular__items'>
                    <img src={kotak} />
                  </div>
                </div>
                <div className='col-xl-2 col-lg-3  col-md-4 col-sm-4 col-6 col-6'>
                  <div className='popular__items'>
                    <img src={hdfc} />
                  </div>
                </div>
                <div className='col-xl-2 col-lg-3  col-md-4 col-sm-4 col-6 col-6'>
                  <div className='popular__items'>
                    <img src={adityabirla} />
                  </div>
                </div>
                <div className='col-xl-2 col-lg-3  col-md-4 col-sm-4 col-6 col-6'>
                  <div className='popular__items'>
                    <img src={federal} />
                  </div>
                </div>
                <div className='col-xl-2 col-lg-3  col-md-4 col-sm-4 col-6 col-6'>
                  <div className='popular__items'>
                    <img src={kotak} />
                  </div>
                </div>
                <div className='col-xl-2 col-lg-3  col-md-4 col-sm-4 col-6 col-6'>
                  <div className='popular__items'>
                    <img src={bajaj} />
                  </div>
                </div>
                <div className='col-xl-2 col-lg-3  col-md-4 col-sm-4 col-6 col-6'>
                  <div className='popular__items'>
                    <img src={hdfc} />
                  </div>
                </div>
                <div className='col-xl-2 col-lg-3  col-md-4 col-sm-4 col-6 col-6'>
                  <div className='popular__items'>
                    <img src={federal} />
                  </div>
                </div>
              </div>
            </div>
            <div
              class='tab-pane fade'
              id='nav-profile'
              role='tabpanel'
              aria-labelledby='nav-profile-tab'
            >
              <div className='row g-lg-4 g-md-4 g-2'>
                <div className='col-xl-2 col-lg-3  col-md-4 col-sm-4 col-6 col-6'>
                  <div className='popular__items'>
                    <img src={bajaj} />
                  </div>
                </div>
                <div className='col-xl-2 col-lg-3  col-md-4 col-sm-4 col-6 col-6'>
                  <div className='popular__items'>
                    <img src={kotak} />
                  </div>
                </div>
                <div className='col-xl-2 col-lg-3  col-md-4 col-sm-4 col-6 col-6'>
                  <div className='popular__items'>
                    <img src={hdfc} />
                  </div>
                </div>
                <div className='col-xl-2 col-lg-3  col-md-4 col-sm-4 col-6 col-6'>
                  <div className='popular__items'>
                    <img src={adityabirla} />
                  </div>
                </div>
                <div className='col-xl-2 col-lg-3  col-md-4 col-sm-4 col-6 col-6'>
                  <div className='popular__items'>
                    <img src={federal} />
                  </div>
                </div>
              </div>
            </div>
            <div
              class='tab-pane fade'
              id='nav-contact'
              role='tabpanel'
              aria-labelledby='nav-contact-tab'
            >
              <div className='row g-lg-4 g-md-4 g-2'>
                <div className='col-xl-2 col-lg-3  col-md-4 col-sm-4 col-6 col-6'>
                  <div className='popular__items'>
                    <img src={bajaj} />
                  </div>
                </div>
                <div className='col-xl-2 col-lg-3  col-md-4 col-sm-4 col-6 col-6'>
                  <div className='popular__items'>
                    <img src={kotak} />
                  </div>
                </div>
                <div className='col-xl-2 col-lg-3  col-md-4 col-sm-4 col-6 col-6'>
                  <div className='popular__items'>
                    <img src={hdfc} />
                  </div>
                </div>
                <div className='col-xl-2 col-lg-3  col-md-4 col-sm-4 col-6 col-6'>
                  <div className='popular__items'>
                    <img src={adityabirla} />
                  </div>
                </div>
                <div className='col-xl-2 col-lg-3  col-md-4 col-sm-4 col-6 col-6'>
                  <div className='popular__items'>
                    <img src={federal} />
                  </div>
                </div>
                <div className='col-xl-2 col-lg-3  col-md-4 col-sm-4 col-6 col-6'>
                  <div className='popular__items'>
                    <img src={kotak} />
                  </div>
                </div>

                <div className='col-xl-2 col-lg-3  col-md-4 col-sm-4 col-6 col-6'>
                  <div className='popular__items'>
                    <img src={federal} />
                  </div>
                </div>
              </div>
            </div>
            <div
              class='tab-pane fade'
              id='nav-contact1'
              role='tabpanel'
              aria-labelledby='nav-contact-tab1'
            >
              <div className='row g-lg-4 g-md-4 g-2'>
                <div className='col-xl-2 col-lg-3  col-md-4 col-sm-4 col-6 col-6'>
                  <div className='popular__items'>
                    <img src={bajaj} />
                  </div>
                </div>
                <div className='col-xl-2 col-lg-3  col-md-4 col-sm-4 col-6 col-6'>
                  <div className='popular__items'>
                    <img src={kotak} />
                  </div>
                </div>
                <div className='col-xl-2 col-lg-3  col-md-4 col-sm-4 col-6 col-6'>
                  <div className='popular__items'>
                    <img src={hdfc} />
                  </div>
                </div>
                <div className='col-xl-2 col-lg-3  col-md-4 col-sm-4 col-6 col-6'>
                  <div className='popular__items'>
                    <img src={adityabirla} />
                  </div>
                </div>
                <div className='col-xl-2 col-lg-3  col-md-4 col-sm-4 col-6 col-6'>
                  <div className='popular__items'>
                    <img src={federal} />
                  </div>
                </div>
                <div className='col-xl-2 col-lg-3  col-md-4 col-sm-4 col-6 col-6'>
                  <div className='popular__items'>
                    <img src={kotak} />
                  </div>
                </div>
                <div className='col-xl-2 col-lg-3  col-md-4 col-sm-4 col-6 col-6'>
                  <div className='popular__items'>
                    <img src={bajaj} />
                  </div>
                </div>
                <div className='col-xl-2 col-lg-3  col-md-4 col-sm-4 col-6 col-6'>
                  <div className='popular__items'>
                    <img src={hdfc} />
                  </div>
                </div>
                <div className='col-xl-2 col-lg-3  col-md-4 col-sm-4 col-6 col-6'>
                  <div className='popular__items'>
                    <img src={federal} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Brand
