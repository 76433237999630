import React from 'react'
import '../InsuranceCard/Insurance.css'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import health from '../../../src/Assets/health-insurance (1).png'
import bike from '../../../src/Assets/bike.png'
import car from '../../../src/Assets/car.png'
import pet from '../../../src/Assets/pet-insurance.png'
import { Link } from 'react-router-dom'

const Insurance = () => {
  return (
    <section className='top__reted'>
      <div className='container'>
        <div className='row align-items-center mb-3 g-3 justify-content-between'>
          <div className='col-lg-5'>
            <div className='section__title'>
              <h4 className='sub ralt base mb-16 wow fadeInUp'>Categories</h4>
              <h2 className='title wow fadeInUp'>
                Explore Our Top-Rated Categories
              </h2>
            </div>
          </div>
          <div className='col-lg-3'>
            <Link to='' className='cmn--btn wow fadeInDown'>
              <span>See All Categories</span>
            </Link>
          </div>
        </div>

        <div className='top__ratedwrapper'>
          <div className='row g-3'>
            <div className='col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-6'>
              <div
                className='top__items suctom__space bgwhite round16 bgwhite'
                tabindex='-1'
              >
                <div className='icon mx-auto d-flex align-items-center justify-content-center boxes2 round50'>
                  <i className='material-symbols-outlined icolor1'>
                    <img src={health} />
                  </i>
                </div>
                <div className='content text-center mt-24'>
                  <h3 className='mb-16'>
                    <Link to='' className='base'>
                      Health Insurance
                    </Link>
                  </h3>
                  <p className='fs-6 fw-400 inter ptext2 mb-3'>
                    Avail Cashless Treatment Anywhere
                  </p>
                  <Link
                    to=''
                    className='arrow m-auto boxes4 round50 d-flex align-items-center justify-content-center'
                  >
                    <ChevronRightIcon />
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-6'>
              <div
                className='top__items suctom__space bgwhite round16 bgwhite'
                tabindex='-1'
              >
                <div className='icon mx-auto d-flex align-items-center justify-content-center boxes2 round50'>
                  <i className='material-symbols-outlined icolor1'>
                    <img src={bike} />
                  </i>
                </div>
                <div className='content text-center mt-24'>
                  <h3 className='mb-16'>
                    <Link to='' className='base'>
                      Bike Insurance
                    </Link>
                  </h3>
                  <p className='fs-6 fw-400 inter ptext2 mb-3'>
                    Buy Two Wheeler Insurance Real Fast
                  </p>
                  <Link
                    to=''
                    className='arrow m-auto boxes4 round50 d-flex align-items-center justify-content-center'
                  >
                    <ChevronRightIcon />
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-6'>
              <div
                className='top__items suctom__space bgwhite round16 bgwhite'
                tabindex='-1'
              >
                <div className='icon mx-auto d-flex align-items-center justify-content-center boxes2 round50'>
                  <i className='material-symbols-outlined icolor1'>
                    <img src={car} />
                  </i>
                </div>
                <div className='content text-center mt-24'>
                  <h3 className='mb-16'>
                    <Link to='' className='base'>
                      Car Insurance
                    </Link>
                  </h3>
                  <p className='fs-6 fw-400 inter ptext2 mb-3'>
                    Irresistible Deals On Your Car Insurance
                  </p>
                  <Link
                    to=''
                    className='arrow m-auto boxes4 round50 d-flex align-items-center justify-content-center'
                  >
                    <ChevronRightIcon />
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-6'>
              <div
                className='top__items suctom__space bgwhite round16 bgwhite'
                tabindex='-1'
              >
                <div className='icon mx-auto d-flex align-items-center justify-content-center boxes2 round50'>
                  <i className='material-symbols-outlined icolor1'>
                    <img src={pet} />
                  </i>
                </div>
                <div className='content text-center mt-24'>
                  <h3 className='mb-16'>
                    <Link to='' className='base'>
                      Pet Insurance
                    </Link>
                  </h3>
                  <p className='fs-6 fw-400 inter ptext2 mb-3'>
                    Let's Secure Your Pet
                  </p>
                  <Link
                    to=''
                    className='arrow m-auto boxes4 round50 d-flex align-items-center justify-content-center'
                  >
                    <ChevronRightIcon />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Insurance
