import React from 'react'
import '../Banner/Banner.css'
import bannerImg from '../../../src/Assets/banner-thumb1.webp'
import bannerbgImg from '../../../src/Assets/banner-thumbbg1.webp'
import { Link } from 'react-router-dom'

const Banner = () => {
  return (
    <section class='banner__section bgadd overhid ralt'>
      <div class='container'>
        <div class='row justify-content-between'>
          <div class='col-xl-6 col-lg-6'>
            <div class='banner__content ralt'>
              <h4
                class='sub ralt base mb-3 wow fadeInDown'
                data-wow-duration='0.4s'
              >
                Our Top Picks
              </h4>
              <span
                class='d1 mb-lg-5 mb-md-3 mb-2  title wow fadeInDown'
                data-wow-duration='0.6s'
              >
                Find the <span class='gratext'>Perfect</span> Credit Card
              </span>
              <p
                class='mb-lg-5 mb-lg-4 mb-3 title wow fadeInDown'
                data-wow-duration='0.8s'
              >
                Are you looking for a credit card that fits your needs? Our
                credit card marketplace makes it easy to find the perfect Card.
              </p>
              <div
                class='btn__grp d-flex align-items-center wow fadeInDown'
                data-wow-duration='1s'
              >
                <Link to='' class='cmn--btn'>
                  <span>Listing Card</span>
                </Link>
                <Link to='' class='outline__btn'>
                  <span>Register Now</span>
                </Link>
              </div>
            </div>
          </div>
          <div class='col-xl-5 col-lg-5'>
            <div class='banner__shapethumb ralt'>
              <div class='thumb'>
                <img
                  alt='banner'
                  loading='lazy'
                  width='551'
                  height='711'
                  decoding='async'
                  data-nimg='1'
                  class='img-fluid'
                  src={bannerImg}
                  style={{ color: 'transparent' }}
                />
              </div>
              <div class='circle__thumb'>
                <img
                  alt='shape'
                  loading='lazy'
                  width='736'
                  height='702'
                  decoding='async'
                  data-nimg='1'
                  src={bannerbgImg}
                  style={{ color: 'transparent' }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Banner
