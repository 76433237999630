import React from 'react'
import '../Footer/Footer.css'
import logo from '../../../../src/Assets/logo.png'
import PhoneIcon from '@mui/icons-material/Phone'
import AttachEmailIcon from '@mui/icons-material/AttachEmail'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import SendIcon from '@mui/icons-material/Send'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer className='footer__section bgadd'>
      <div className='container'>
        <div className='footer__top'>
          <div className='row g-4'>
            <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6'>
              <div className='footer__item'>
                <a className='footer__logo mb-4 d-block' href='/'>
                  <img
                    alt='logo'
                    loading='lazy'
                    width='295'
                    height='60'
                    decoding='async'
                    data-nimg='1'
                    src={logo}
                  />
                </a>
                <p className='fs-6 inter fw-400 text-white mb-3'>
                  Are you looking for a credit card that fits your needs? Our
                  credit card market place makes it easy to find the perfect
                  card.
                </p>
                <ul className='social d-flex align-items-center'>
                  <li>
                    <Link to='' className='social__icon'>
                      <i className='fab fa-facebook-f'></i>
                    </Link>
                  </li>
                  <li>
                    <Link to='' className='social__icon'>
                      <i className='fab fa-instagram'></i>
                    </Link>
                  </li>
                  <li>
                    <Link to='' className='social__icon'>
                      <i className='fab fa-twitter'></i>
                    </Link>
                  </li>
                  <li>
                    <Link to='' className='social__icon'>
                      <i className='fab fa-linkedin-in'></i>
                    </Link>
                  </li>
                  <li>
                    <Link to='' className='social__icon'>
                      <i className='fab fa-vimeo-v'></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-xxl-2 col-xl-2 col-lg-2 col-md-6 col-sm-6'>
              <div className='footer__item'>
                <a className='footer__title  fw-600 inter text-white  d-block'>
                  Quick Link
                </a>
                <ul className='quick__link'>
                  <li>
                    <Link
                      to='/about'
                      className='fs-6 fw-400 inter text-white d-block'
                    >
                      About us
                    </Link>
                  </li>
                  <li>
                    <Link
                      to=''
                      className='fs-6 fw-400 inter text-white d-block'
                    >
                      Categories
                    </Link>
                  </li>
                  <li>
                    <Link
                      to=''
                      className='fs-6 fw-400 inter text-white d-block'
                    >
                      Compare Cards
                    </Link>
                  </li>
                  <li>
                    <Link
                      to=''
                      className='fs-6 fw-400 inter text-white d-block'
                    >
                      FAQs
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6'>
              <div className='footer__item'>
                <a
                  className='footer__title fz-24 fw-600 inter text-white mb-24 d-block'
                  href='URL:void(0)'
                >
                  Contact
                </a>
                <ul className='footer__contact'>
                  <li>
                    <Link
                      to='tel:9876543211'
                      className='fs-6 d-flex align-items-start gap-3 fw-400 inter text-white d-block'
                    >
                      <i className='material-symbols-outlined cmn__icon'>
                        <PhoneIcon />
                      </i>
                      <span> (316) 555-0116</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to=''
                      className='fs-6 d-flex align-items-stat gap-3 fw-400 inter text-white d-block'
                    >
                      <i className='material-symbols-outlined cmn__icon'>
                        <AttachEmailIcon />
                      </i>
                      <span>info@example.com</span>
                    </Link>
                  </li>
                  <li>
                    <div className='fs-6 d-flex align-items-stat gap-3 fw-400 inter text-white d-block'>
                      <i className='material-symbols-outlined cmn__icon'>
                        <LocationOnIcon />
                      </i>
                      <span>
                        601, 6th Floor, Brij Anukampa Tower, Ashok Marg,
                        C-scheme, Jaipur, 302001
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-xxl-4 col-xl-3 col-lg-3 col-md-6 col-sm-6'>
              <div className='footer__item'>
                <Link
                  to=''
                  className='footer__title fs-5 fw-600 inter text-white mb-4 d-block'
                >
                  Newsletter
                </Link>
                <p className='fs-6 fw-400 inter mb-4 text-white'>
                  Subscribe our newsletter to get our latest update &amp; adress
                </p>
                <form className='d-flex align-items-center' action='#'>
                  <input type='text' placeholder='Email address' required='' />
                  <button
                    aria-label='Submit'
                    type='submit'
                    className='cmn--btn'
                  >
                    <span>
                      <i className='material-symbols-outlined'>
                        <SendIcon />
                      </i>
                    </span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className='footer__bottom d-flex align-items-center'>
          <p className='fs-6 fw-400 inter text-white'>
            Copyright © 2024
            <a className='base2 ps-2' href='#'>
              FinFAST.
            </a>
          </p>
          <ul className='help__support d-flex align-items-center'>
            <li>
              <Link
                to='/privacy-policy'
                className='text-white fs-6 fw-400 inter'
              >
                Privacy policy
              </Link>
            </li>
            <li>
              <Link to='/terms' className='text-white fs-6 fw-400 inter'>
                Terms &amp; Conditions
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default Footer
