import React from 'react'
import '../ReviewSection/ReviewSection.css'
import Slider from 'react-slick'
import StarIcon from '@mui/icons-material/Star'
import testimonilClient from '../../../src/Assets/testimonial1.webp'
import FormatQuoteIcon from '@mui/icons-material/FormatQuote'

const ReviewSection = () => {
  var testimonialSlider = {
    dots: true,
    nav: false,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 2000,
    spacebetween: 20,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }
  return (
    <section class='testimonial__sectiontwo ralt pt-120 pb-120'>
      <div class='container'>
        <div class='row justify-content-center'>
          <div class='col-lg-6'>
            <div class='section__title ralt text-center mb-60'>
              <h4 class='sub mb-3 ralt base2 wow fadeInDown'>
                Top Card Reviews
              </h4>
              <h2 class='text-white mb-4 wow fadeInUp'>
                Unlock the power of our unique credit card
              </h2>
              <p class='text-white fs-6 fw-400 inter wow fadeInDown mb-md-5'>
                Buying a credit card from a credit card marketplace website
                involves browsing, comparing, selecting
              </p>
            </div>
          </div>
        </div>
        <div class='testimonial__wraptwo '>
          <Slider {...testimonialSlider}>
            <div>
              <div class='testimonial__items ralt bgadd round16 '>
                <div class='quote round50 m-auto d-flex align-items-center justify-content-center'>
                  <i class='material-symbols-outlined base'>
                    <FormatQuoteIcon className='card_icon' />
                  </i>
                </div>
                <div class='ratting mb-3 pt-3 justify-content-center d-flex align-items-center gap-2'>
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                </div>
                <p class='ptext3 inter fs-6 text-center fw-400 mb-3'>
                  Thanks to the credit card marketplace website, I was able to
                  find the perfect credit card
                </p>
                <div class='d-flex justify-content-center align-items-center gap-3'>
                  <div class='thumb'>
                    <img
                      alt='annette'
                      loading='lazy'
                      width='60'
                      height='60'
                      decoding='async'
                      data-nimg='1'
                      src={testimonilClient}
                    />
                  </div>
                  <div className='cont'>
                    <span className='fs-5 fw-bold inter ptext'>
                      Annette Black
                    </span>
                    <span className='fs-6 d-block ptext fw-400 inter'>
                      Canada
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div class='testimonial__items ralt bgadd round16 '>
                <div class='quote round50 m-auto d-flex align-items-center justify-content-center'>
                  <i class='material-symbols-outlined base'>
                    <FormatQuoteIcon className='card_icon' />
                  </i>
                </div>
                <div class='ratting mb-3 pt-3 justify-content-center d-flex align-items-center gap-2'>
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                </div>
                <p class='ptext3 inter fs-6 text-center fw-400 mb-3'>
                  Thanks to the credit card marketplace website, I was able to
                  find the perfect credit card
                </p>
                <div class='d-flex justify-content-center align-items-center gap-3'>
                  <div class='thumb'>
                    <img
                      alt='annette'
                      loading='lazy'
                      width='60'
                      height='60'
                      decoding='async'
                      data-nimg='1'
                      src={testimonilClient}
                    />
                  </div>
                  <div className='cont'>
                    <span className='fs-5 fw-bold inter ptext'>
                      Annette Black
                    </span>
                    <span className='fs-6 d-block ptext fw-400 inter'>
                      Canada
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class='testimonial__items ralt bgadd round16 '>
                <div class='quote round50 m-auto d-flex align-items-center justify-content-center'>
                  <i class='material-symbols-outlined base'>
                    <FormatQuoteIcon className='card_icon' />
                  </i>
                </div>
                <div class='ratting mb-3 pt-3 justify-content-center d-flex align-items-center gap-2'>
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                </div>
                <p class='ptext3 inter fs-6 text-center fw-400 mb-3'>
                  Thanks to the credit card marketplace website, I was able to
                  find the perfect credit card
                </p>
                <div class='d-flex justify-content-center align-items-center gap-3'>
                  <div class='thumb'>
                    <img
                      alt='annette'
                      loading='lazy'
                      width='60'
                      height='60'
                      decoding='async'
                      data-nimg='1'
                      src={testimonilClient}
                    />
                  </div>
                  <div className='cont'>
                    <span className='fs-5 fw-bold inter ptext'>
                      Annette Black
                    </span>
                    <span className='fs-6 d-block ptext fw-400 inter'>
                      Canada
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class='testimonial__items ralt bgadd round16 '>
                <div class='quote round50 m-auto d-flex align-items-center justify-content-center'>
                  <i class='material-symbols-outlined base'>
                    <FormatQuoteIcon className='card_icon' />
                  </i>
                </div>
                <div class='ratting mb-3 pt-3 justify-content-center d-flex align-items-center gap-2'>
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                </div>
                <p class='ptext3 inter fs-6 text-center fw-400 mb-3'>
                  Thanks to the credit card marketplace website, I was able to
                  find the perfect credit card
                </p>
                <div class='d-flex justify-content-center align-items-center gap-3'>
                  <div class='thumb'>
                    <img
                      alt='annette'
                      loading='lazy'
                      width='60'
                      height='60'
                      decoding='async'
                      data-nimg='1'
                      src={testimonilClient}
                    />
                  </div>
                  <div className='cont'>
                    <span className='fs-5 fw-bold inter ptext'>
                      Annette Black
                    </span>
                    <span className='fs-6 d-block ptext fw-400 inter'>
                      Canada
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class='testimonial__items ralt bgadd round16 '>
                <div class='quote round50 m-auto d-flex align-items-center justify-content-center'>
                  <i class='material-symbols-outlined base'>
                    <FormatQuoteIcon className='card_icon' />
                  </i>
                </div>
                <div class='ratting mb-3 pt-3 justify-content-center d-flex align-items-center gap-2'>
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                </div>
                <p class='ptext3 inter fs-6 text-center fw-400 mb-3'>
                  Thanks to the credit card marketplace website, I was able to
                  find the perfect credit card
                </p>
                <div class='d-flex justify-content-center align-items-center gap-3'>
                  <div class='thumb'>
                    <img
                      alt='annette'
                      loading='lazy'
                      width='60'
                      height='60'
                      decoding='async'
                      data-nimg='1'
                      src={testimonilClient}
                    />
                  </div>
                  <div className='cont'>
                    <span className='fs-5 fw-bold inter ptext'>
                      Annette Black
                    </span>
                    <span className='fs-6 d-block ptext fw-400 inter'>
                      Canada
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </section>
  )
}

export default ReviewSection
