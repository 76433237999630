import React, { useState } from 'react'
import '../Card/Card.css'
import cardImg from '../../../src/Assets/card.svg'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import loanImg from '../../../src/Assets/loan.svg'
import propertyImg from '../../../src/Assets/property.svg'
import bussiness from '../../../src/Assets/business.svg'
import { Link } from 'react-router-dom'
import CalculatorForm from '../Common/CalculatorForm/CalculatorForm'

const Card = () => {
  const [calculatorform, setCalculatorForm] = useState(false)
  return (
    <div className='card_section'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-lg-6'>
            <div class='section__title text-center mb-60'>
              <h4 class='sub ralt base mb-2'>How It's Work</h4>
              <h2 class='title mb-4'>
                Get ready to buy our unique credit card
              </h2>
            </div>
          </div>
        </div>
        <div class='row g-4 pt-60 justify-content-center'>
          <div class='col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-6'>
            <div class='select__cardbox boxes1 d-flex'>
              <div class='thumb transition d-flex align-items-center justify-content-center'>
                <img alt='img' src={cardImg} />
              </div>
              <div className='thumb-content'>
                <h4 class='title'>Credit Cards</h4>
                <p>
                  From 50+ Options, Choose a card matching your lifestyle &
                  needs
                </p>
                <Link
                  to='/calculatorforms'
                  class='gap-2 outline__btn d-flex align-items-center '
                >
                  <span>Get Best Offer</span>

                  <span class='icon'>
                    <ArrowRightAltIcon />
                  </span>
                </Link>
              </div>
            </div>
          </div>
          <div class='col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-6'>
            <div class='select__cardbox boxes2 d-flex '>
              <div class='thumb transition d-flex align-items-center justify-content-center'>
                <img alt='img' src={loanImg} />
              </div>
              <div className='thumb-content'>
                <h4 class='title'>Personal Loan</h4>
                <p>
                  From 50+ Options, Choose a card matching your lifestyle &
                  needs
                </p>
                <Link
                  to='/calculatorforms'
                  class='gap-2 outline__btn d-flex align-items-center '
                >
                  <span>Get Best Offer</span>

                  <span class='icon'>
                    <ArrowRightAltIcon />
                  </span>
                </Link>
              </div>
            </div>
          </div>
          <div class='col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-6'>
            <div class='select__cardbox boxes3 d-flex'>
              <div class='thumb transition d-flex align-items-center justify-content-center'>
                <img alt='img' src={propertyImg} />
              </div>
              <div className='thumb-content'>
                <h4 class='title'>Property Loan</h4>
                <p>
                  From 50+ Options, Choose a card matching your lifestyle &
                  needs
                </p>
                <Link
                  to='/calculatorforms'
                  class='gap-2 outline__btn d-flex align-items-center '
                >
                  <span>Get Best Offer</span>

                  <span class='icon'>
                    <ArrowRightAltIcon />
                  </span>
                </Link>
              </div>
            </div>
          </div>
          <div class='col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-6'>
            <div class='select__cardbox boxes4 d-flex '>
              <div class='thumb transition d-flex align-items-center justify-content-center'>
                <img alt='img' src={bussiness} />
              </div>
              <div className='thumb-content'>
                <h4 class='title'>Business Loan</h4>
                <p>
                  From 50+ Options, Choose a card matching your lifestyle &
                  needs
                </p>
                <Link
                  to='/calculatorforms'
                  class='gap-2 outline__btn d-flex align-items-center '
                >
                  <span>Get Best Offer</span>

                  <span class='icon'>
                    <ArrowRightAltIcon />
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        {calculatorform && (
          <CalculatorForm
            show={calculatorform}
            setModel={setCalculatorForm}
            setShowSignup={setCalculatorForm}
          />
        )}
      </div>
    </div>
  )
}

export default Card
