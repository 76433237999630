import React, { useState, useEffect } from 'react'
import Header from '../Header/Header'
import signinimg from '../../../Assets/login-modal.png'
import Bannerbreadcrump from '../../Bannerbreadcrump/Bannerbreadcrump'
import SouthIcon from '@mui/icons-material/South'
import appimg from '../../../../src/Assets/loan-process.png'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import Select from 'react-select'

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import contactbg from '../../../../src/Assets/contactus.webp'
import chock1 from '../../../../src/Assets/chok1.webp'
import chock2 from '../../../../src/Assets/chok2.webp'
import chock3 from '../../../../src/Assets/chok3.webp'
import chock4 from '../../../../src/Assets/chok4.webp'
import chock5 from '../../../../src/Assets/chok4 (1).webp'

import { Link } from 'react-router-dom'

const Calculatorforms = () => {
  const [step, setStep] = useState(1) // State to manage form steps
  const [email, setEmail] = useState('')
  const [otp, setOtp] = useState('')
  const [name, setName] = useState('')
  const [pincode, setPincode] = useState('')
  const [category, setCategory] = useState('')
  const [income, setIncome] = useState('')
  const [incomePlaceholder, setIncomePlaceholder] = useState(
    'Enter Your Income...'
  )
  const categories = [
    { value: 'salaried', label: 'Salaried' },
    { value: 'self-employed', label: 'Self-Employed' },
    { value: 'business-owner', label: 'Business Owner' }
    // Add more categories as needed
  ]

  const handleEmailChange = e => setEmail(e.target.value)
  const handleOtpChange = e => setOtp(e.target.value)
  const handleNameChange = e => setName(e.target.value)
  const handlePincodeChange = e => setPincode(e.target.value)
  const handleCategoryChange = selectedOption =>
    setCategory(selectedOption.value)
  const handleIncomeChange = e => setIncome(e.target.value)

  useEffect(() => {
    if (category === 'salaried') {
      setIncomePlaceholder('Income (Monthly)')
    } else if (category === 'self-employed' || category === 'business-owner') {
      setIncomePlaceholder('Income (Yearly)')
    }
  }, [category])

  const handleLoginSubmit = e => {
    e.preventDefault()
    // Handle email authentication
    // On success, proceed to OTP step
    setStep(2)
  }

  const handleOtpSubmit = e => {
    e.preventDefault()
    // Handle OTP verification
    // On success, proceed to details step
    setStep(3)
  }

  const handleDetailsSubmit = e => {
    e.preventDefault()
    // Handle final form submission with all details
  }

  const handleBackClick = () => {
    setStep(step - 1)
  }
  return (
    <div>
      <Header />
      <section className='banner__breadcumn overhid ralt'>
        <div className='breadcumnd__wrapper'>
          <div className='container'>
            <div className='row align-items-center justify-content-between gy-5 gy-md-0'>
              <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                <div className='breadcumnd__content text-center'>
                  <span className='d4 mb-5 text-white text-center'>
                    Login us
                  </span>
                  <ul className='breadcumnd__link flex-wrap gap-1 d-flex align-items-center justify-content-center'>
                    <li className='fs-6 base2 fw-medium inter'>
                      <a className='text-white' href='/'>
                        Home
                      </a>
                    </li>
                    <li>
                      <i className='material-symbols-outlined text-white'>
                        <KeyboardArrowRightIcon />
                      </i>
                    </li>
                    <li className='fs-6 base2 fw-medium inter'>
                      <a className='text-white' href='URL:void(0)'>
                        Pages
                      </a>
                    </li>
                    <li>
                      <i className='material-symbols-outlined text-white'>
                        <KeyboardArrowRightIcon />
                      </i>
                    </li>
                    <li className='fs-6 base2 fw-medium inter'>Login Us</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='element1'>
          <img
            alt='element'
            loading='lazy'
            width='181'
            height='88'
            decoding='async'
            data-nimg='1'
            className='img-fluid'
            src={chock1}
          />
        </div>
        <div className='element2'>
          <img
            alt='element'
            loading='lazy'
            width='186'
            height='126'
            decoding='async'
            data-nimg='1'
            className='img-fluid'
            src={chock2}
          />
        </div>
        <div className='element3'>
          <img
            alt='element'
            loading='lazy'
            width='127'
            height='86'
            decoding='async'
            data-nimg='1'
            className='img-fluid'
            src={chock3}
          />
        </div>
        <div className='element4'>
          <img
            alt='element'
            loading='lazy'
            width='232'
            height='113'
            decoding='async'
            data-nimg='1'
            className='img-fluid'
            src={chock4}
          />
        </div>
        <div className='element5'>
          <img
            alt='element'
            loading='lazy'
            width='127'
            height='86'
            decoding='async'
            data-nimg='1'
            className='img-fluid'
            src={chock5}
          />
        </div>
      </section>
      <section class='app__section ralt bgadd'>
        <div class='container'>
          <div class='row g-4 align-items-center justify-content-between'>
            <div class='col-xl-5 col-lg-5 col-md-6 order-2 order-md-1'>
              <div class='app__thumb ralt'>
                <img
                  alt='card'
                  loading='lazy'
                  width='526'
                  height='567'
                  decoding='async'
                  data-nimg='1'
                  class='img-fluid'
                  src={appimg}
                />
              </div>
            </div>
            <div class='col-xl-6 col-lg-7 col-md-6 order-md-2'>
              <div class='app__content'>
                <div class='section__title'>
                  <h4 class='sub ralt base mb-3 wow fadeInDown'>
                    CARD SAHI, MILEGA YAHIN!
                  </h4>
                  <h2 class='title mb-3 wow fadeInUp'>
                    Get best Credit Cards for all your needs
                  </h2>
                </div>
                <div className='signinmodal_box bg-transparent'>
                  <div className='row justify-content-center align-items-center'>
                    <div className='col-lg-12'>
                      <div className='signup__boxes round16'>
                        <div className='d-flex justify-content-start align-items-center mb-4 gap-2'>
                          {step > 1 && (
                            <button
                              className='btn btn-link p-0'
                              onClick={handleBackClick}
                            >
                              <KeyboardBackspaceIcon
                                style={{ fontSize: '30px', color: '#000' }}
                              />
                            </button>
                          )}
                          <h3 className='title mb-0'>Welcome Back!</h3>
                        </div>
                        {step === 1 ? (
                          <form
                            onSubmit={handleLoginSubmit}
                            className='write__review'
                          >
                            <div className='row g-4'>
                              <div className='col-lg-12'>
                                <div className='frm__grp'>
                                  <label
                                    htmlFor='email'
                                    className='fz-18 fw-500 inter title mb-2'
                                  >
                                    Enter Your Mobile Number
                                  </label>
                                  <input
                                    id='email'
                                    placeholder='Enter Your Number'
                                    required=''
                                    type='number'
                                    value={email}
                                    onChange={handleEmailChange}
                                  />
                                </div>
                              </div>
                              <div className='col-lg-6'>
                                <div className='frm__grp'>
                                  <button
                                    aria-label='Sign in'
                                    type='submit'
                                    className='cmn--btn'
                                  >
                                    <span>Sign In</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        ) : step === 2 ? (
                          <form
                            onSubmit={handleOtpSubmit}
                            className='write__review'
                          >
                            <div className='row g-4'>
                              <div className='col-lg-12'>
                                <div className='frm__grp'>
                                  <label
                                    htmlFor='otp'
                                    className='fz-18 fw-500 inter title mb-2'
                                  >
                                    Enter OTP
                                  </label>
                                  <input
                                    id='otp'
                                    placeholder='Enter OTP...'
                                    required=''
                                    type='text'
                                    value={otp}
                                    onChange={handleOtpChange}
                                  />
                                </div>
                              </div>
                              <div className='col-lg-6'>
                                <div className='frm__grp'>
                                  <button
                                    aria-label='Verify OTP'
                                    type='submit'
                                    className='cmn--btn'
                                  >
                                    <span>Verify OTP</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        ) : (
                          <form
                            onSubmit={handleDetailsSubmit}
                            className='write__review'
                          >
                            <div className='row g-4'>
                              <div className='col-lg-6 col-md-12 col-12'>
                                <div className='frm__grp'>
                                  <label
                                    htmlFor='name'
                                    className='fz-18 fw-500 inter title mb-2'
                                  >
                                    Name
                                  </label>
                                  <input
                                    id='name'
                                    placeholder='Enter Your Name...'
                                    required=''
                                    type='text'
                                    value={name}
                                    onChange={handleNameChange}
                                  />
                                </div>
                              </div>
                              <div className='col-lg-6 col-md-12 col-12'>
                                <div className='frm__grp'>
                                  <label
                                    htmlFor='pincode'
                                    className='fz-18 fw-500 inter title mb-2'
                                  >
                                    Pincode
                                  </label>
                                  <input
                                    id='pincode'
                                    placeholder='Enter Your Pincode...'
                                    required=''
                                    type='text'
                                    value={pincode}
                                    onChange={handlePincodeChange}
                                  />
                                </div>
                              </div>
                              <div className='col-lg-6 col-md-12 col-12'>
                                <div className='frm__grp'>
                                  <label
                                    htmlFor='category'
                                    className='fz-18 fw-500 inter title mb-2'
                                  >
                                    Category
                                  </label>
                                  <Select
                                    options={categories}
                                    className='selector'
                                    placeholder='Select Category'
                                    onChange={handleCategoryChange}
                                    components={{
                                      IndicatorSeparator: () => null
                                    }}
                                  />
                                </div>
                              </div>
                              <div className='col-lg-6 col-md-12 col-12'>
                                <div className='frm__grp'>
                                  <label
                                    htmlFor={incomePlaceholder}
                                    className='fz-18 fw-500 inter title mb-2'
                                  >
                                    {incomePlaceholder}
                                  </label>
                                  <input
                                    id='income'
                                    placeholder={incomePlaceholder}
                                    required=''
                                    type='text'
                                    value={income}
                                    onChange={handleIncomeChange}
                                  />
                                </div>
                              </div>
                              <div className='col-lg-6'>
                                <div className='frm__grp'>
                                  <button
                                    aria-label='Submit Details'
                                    type='submit'
                                    className='cmn--btn'
                                  >
                                    <span>Submit</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Calculatorforms
