import React, { useState, useEffect } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import signinimg from '../../../Assets/login-modal.png'
import Select from 'react-select'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'

function CalculatorForm ({ show, setModel, setShowSignup }) {
  const [step, setStep] = useState(1) // State to manage form steps
  const [email, setEmail] = useState('')
  const [otp, setOtp] = useState('')
  const [name, setName] = useState('')
  const [pincode, setPincode] = useState('')
  const [category, setCategory] = useState('')
  const [income, setIncome] = useState('')
  const [incomePlaceholder, setIncomePlaceholder] = useState(
    'Enter Your Income...'
  )

  const toggleSigninModel = () => setModel(!show)
  const openSignupModal = () => {
    setModel(false)
    setShowSignup(true)
  }

  const categories = [
    { value: 'salaried', label: 'Salaried' },
    { value: 'self-employed', label: 'Self-Employed' },
    { value: 'business-owner', label: 'Business Owner' }
    // Add more categories as needed
  ]

  const handleEmailChange = e => setEmail(e.target.value)
  const handleOtpChange = e => setOtp(e.target.value)
  const handleNameChange = e => setName(e.target.value)
  const handlePincodeChange = e => setPincode(e.target.value)
  const handleCategoryChange = selectedOption =>
    setCategory(selectedOption.value)
  const handleIncomeChange = e => setIncome(e.target.value)

  useEffect(() => {
    if (category === 'salaried') {
      setIncomePlaceholder('Income (Monthly)')
    } else if (category === 'self-employed' || category === 'business-owner') {
      setIncomePlaceholder('Income (Yearly)')
    }
  }, [category])

  const handleLoginSubmit = e => {
    e.preventDefault()
    // Handle email authentication
    // On success, proceed to OTP step
    setStep(2)
  }

  const handleOtpSubmit = e => {
    e.preventDefault()
    // Handle OTP verification
    // On success, proceed to details step
    setStep(3)
  }

  const handleDetailsSubmit = e => {
    e.preventDefault()
    // Handle final form submission with all details
  }

  const handleBackClick = () => {
    setStep(step - 1)
  }

  return (
    <Modal
      isOpen={show}
      toggle={toggleSigninModel}
      centered
      size='lg'
      backdrop='static'
      keyboard={true}
    >
      <ModalBody>
        <div className=''>
          <div className='gsc_modalWrapper'>
            <div onClick={toggleSigninModel} className='v2_close false'>
              <a href='#' className='close-button'>
                <div className='in'>
                  <div className='close-button-block'></div>
                  <div className='close-button-block'></div>
                </div>
                <div className='out'>
                  <div className='close-button-block'></div>
                  <div className='close-button-block'></div>
                </div>
              </a>
            </div>
            <div className='signinmodal_box'>
              <div className='row justify-content-center align-items-center'>
                <div className='col-lg-6'>
                  <div className='signup__boxes round16'>
                    <div className='d-flex justify-content-start align-items-center mb-4 gap-2'>
                      {step > 1 && (
                        <button
                          className='btn btn-link p-0'
                          onClick={handleBackClick}
                        >
                          <KeyboardBackspaceIcon
                            style={{ fontSize: '30px', color: '#000' }}
                          />
                        </button>
                      )}
                      <h3 className='title mb-0'>Welcome Back!</h3>
                    </div>
                    {step === 1 ? (
                      <form
                        onSubmit={handleLoginSubmit}
                        className='write__review'
                      >
                        <div className='row g-4'>
                          <div className='col-lg-12'>
                            <div className='frm__grp'>
                              <label
                                htmlFor='email'
                                className='fz-18 fw-500 inter title mb-2'
                              >
                                Enter Your Mobile Number
                              </label>
                              <input
                                id='email'
                                placeholder='Enter Your Number'
                                required=''
                                type='number'
                                value={email}
                                onChange={handleEmailChange}
                              />
                            </div>
                          </div>
                          <div className='col-lg-6'>
                            <div className='frm__grp'>
                              <button
                                aria-label='Sign in'
                                type='submit'
                                className='cmn--btn'
                              >
                                <span>Sign In</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    ) : step === 2 ? (
                      <form
                        onSubmit={handleOtpSubmit}
                        className='write__review'
                      >
                        <div className='row g-4'>
                          <div className='col-lg-12'>
                            <div className='frm__grp'>
                              <label
                                htmlFor='otp'
                                className='fz-18 fw-500 inter title mb-2'
                              >
                                Enter OTP
                              </label>
                              <input
                                id='otp'
                                placeholder='Enter OTP...'
                                required=''
                                type='text'
                                value={otp}
                                onChange={handleOtpChange}
                              />
                            </div>
                          </div>
                          <div className='col-lg-6'>
                            <div className='frm__grp'>
                              <button
                                aria-label='Verify OTP'
                                type='submit'
                                className='cmn--btn'
                              >
                                <span>Verify OTP</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    ) : (
                      <form
                        onSubmit={handleDetailsSubmit}
                        className='write__review'
                      >
                        <div className='row g-4'>
                          <div className='col-lg-12'>
                            <div className='frm__grp'>
                              <label
                                htmlFor='name'
                                className='fz-18 fw-500 inter title mb-2'
                              >
                                Name
                              </label>
                              <input
                                id='name'
                                placeholder='Enter Your Name...'
                                required=''
                                type='text'
                                value={name}
                                onChange={handleNameChange}
                              />
                            </div>
                          </div>
                          <div className='col-lg-12'>
                            <div className='frm__grp'>
                              <label
                                htmlFor='pincode'
                                className='fz-18 fw-500 inter title mb-2'
                              >
                                Pincode
                              </label>
                              <input
                                id='pincode'
                                placeholder='Enter Your Pincode...'
                                required=''
                                type='text'
                                value={pincode}
                                onChange={handlePincodeChange}
                              />
                            </div>
                          </div>
                          <div className='col-lg-12'>
                            <div className='frm__grp'>
                              <label
                                htmlFor='category'
                                className='fz-18 fw-500 inter title mb-2'
                              >
                                Category
                              </label>
                              <Select
                                options={categories}
                                className='selector'
                                placeholder='Select Category'
                                onChange={handleCategoryChange}
                                components={{
                                  IndicatorSeparator: () => null
                                }}
                              />
                            </div>
                          </div>
                          <div className='col-lg-12'>
                            <div className='frm__grp'>
                              <label
                                htmlFor={incomePlaceholder}
                                className='fz-18 fw-500 inter title mb-2'
                              >
                                {incomePlaceholder}
                              </label>
                              <input
                                id='income'
                                placeholder={incomePlaceholder}
                                required=''
                                type='text'
                                value={income}
                                onChange={handleIncomeChange}
                              />
                            </div>
                          </div>
                          <div className='col-lg-6'>
                            <div className='frm__grp'>
                              <button
                                aria-label='Submit Details'
                                type='submit'
                                className='cmn--btn'
                              >
                                <span>Submit</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    )}
                  </div>
                </div>
                <div className='col-lg-6'>
                  <div className='signup__thumb'>
                    <img
                      alt='img'
                      loading='lazy'
                      width='784'
                      height='636'
                      decoding='async'
                      data-nimg='1'
                      className='img-fluid'
                      src={signinimg}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default CalculatorForm
