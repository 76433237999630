import React from 'react'
import Header from './Components/Common/Header/Header'
import Banner from './Components/Banner/Banner'
import Card from './Components/Card/Card'
import CardProduct from './Components/CardProduct/CardProduct'
import Insurance from './Components/InsuranceCard/Insurance'
import Brand from './Components/Brand/Brand'
import Footer from './Components/Common/Footer/Footer'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './Components/Home/Home'
import SigninModal from './Components/Common/SigninModal'
import SignupModal from './Components/Common/SignupModal/SignupModal'
import Contact from './Components/Contact/Contact'
import Testimonial from './Components/Testimonial/Testimonial'
import Bannerbreadcrump from './Components/Bannerbreadcrump/Bannerbreadcrump'
import About from './Components/About/About'
import Worksection from './Components/Worksection/Worksection'
import ReviewSection from './Components/ReviewSection/ReviewSection'
import Downloadapp from './Components/DownloadApp/Downloadapp'
import { PrivacyTip } from '@mui/icons-material'
import Privacypolicy from './Components/Privacypolicy/Privacypolicy'
import Terms from './Components/Terms & Condition/Terms'
import Calculator from './Components/Calculator/Calculator'
import CalculatorDetail from './Components/CalculatorDetailPage/CalculatorDetail'
import CalculatorForm from './Components/Common/CalculatorForm/CalculatorForm'
import Calculatorforms from './Components/Common/Calculatorforms/Calculatorforms'

// import { Home } from '@mui/icons-material'

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/header' element={<Header />} />
          <Route path='/banner' element={<Banner />} />
          <Route path='/card' element={<Card />} />
          <Route path='/insurance' element={<Insurance />} />
          <Route path='/cardproduct' element={<CardProduct />} />
          <Route path='/brand' element={<Brand />} />
          <Route path='/footer' element={<Footer />} />
          <Route path='/signin' element={<SigninModal />} />
          <Route path='/signup' element={<SignupModal />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/testimonial' element={<Testimonial />} />
          <Route path='/bannerbreadcrumb' element={<Bannerbreadcrump />} />
          <Route path='/about' element={<About />} />
          <Route path='/worksection' element={<Worksection />} />
          <Route path='/reviewsection' element={<ReviewSection />} />
          <Route path='/app' element={<Downloadapp />} />
          <Route path='/privacy-policy' element={<Privacypolicy />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='/calculator' element={<Calculator />} />
          <Route path='/calculator-detail' element={<CalculatorDetail />} />
          <Route path='/calculatorform' element={<CalculatorForm />} />
          <Route path='/calculatorforms' element={<Calculatorforms />} />
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App
