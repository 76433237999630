import React from 'react'
import '../Privacypolicy/Privacypolicy.css'
import Header from '../Common/Header/Header'
import Bannerbreadcrump from '../Bannerbreadcrump/Bannerbreadcrump'
import Footer from '../Common/Footer/Footer'

const Privacypolicy = () => {
  return (
    <div>
      <Header />
      <Bannerbreadcrump />
      <section className='privacy_policy'>
        <div className='container'>
          <div class='section__title mb-0'>
            <h4
              class='sub ralt base mb-3 wow fadeInUp'
              data-wow-duration='1.1s'
            >
              Privacy Policy
            </h4>
            <h2 class='title mb-4 wow fadeInUp' data-wow-duration='1.2s'>
              Privacy Policy
            </h2>
            <p
              class='ptext2 fs-6  fw-400 inter wow fadeInUp mb-4'
              data-wow-duration='1.4s'
            >
              We collect necessary information to provide our Accounting &
              Payroll Processing Services, including personal details (e.g.,
              names, addresses), contact information, and financial data
              required for payroll and accounting purpose
            </p>

            <h2 class='title mb-4 wow fadeInUp' data-wow-duration='1.2s'>
              Information we collect
            </h2>
            <p
              class='ptext2 fs-6  fw-400 inter wow fadeInUp mb-4'
              data-wow-duration='1.4s'
            >
              In the course of providing Accounting & Payroll Processing
              Services, we collect essential information to ensure accurate and
              efficient service delivery. This may include personal details such
              as names, addresses, and contact information, as well as financial
              data necessary for payroll and accounting purposes. We prioritize
              the security and confidentiality of this information, employing
              industry-standard measures to safeguard it. Rest assured, our data
              collection is guided by a commitment to transparency,
            </p>

            <ul class='task__listwrap row-gap-2 mb-4'>
              <li
                class='d-flex gap-2 align-items-center wow fadeInUp'
                data-wow-duration='1.7s'
              >
                <svg
                  class='MuiSvgIcon-root MuiSvgIcon-fontSizeMedium base css-i4bv87-MuiSvgIcon-root'
                  focusable='false'
                  aria-hidden='true'
                  viewBox='0 0 24 24'
                  data-testid='TaskAltIcon'
                >
                  <path d='M22 5.18 10.59 16.6l-4.24-4.24 1.41-1.41 2.83 2.83 10-10zm-2.21 5.04c.13.57.21 1.17.21 1.78 0 4.42-3.58 8-8 8s-8-3.58-8-8 3.58-8 8-8c1.58 0 3.04.46 4.28 1.25l1.44-1.44C16.1 2.67 14.13 2 12 2 6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10c0-1.19-.22-2.33-.6-3.39z'></path>
                </svg>
                <span class='contentbox fz-20 fw-500 title inter'>
                  Accounting & Payroll Processing Services, we collect essential
                  information
                </span>
              </li>
              <li
                class='d-flex gap-2 align-items-center wow fadeInUp'
                data-wow-duration='1.7s'
              >
                <svg
                  class='MuiSvgIcon-root MuiSvgIcon-fontSizeMedium base css-i4bv87-MuiSvgIcon-root'
                  focusable='false'
                  aria-hidden='true'
                  viewBox='0 0 24 24'
                  data-testid='TaskAltIcon'
                >
                  <path d='M22 5.18 10.59 16.6l-4.24-4.24 1.41-1.41 2.83 2.83 10-10zm-2.21 5.04c.13.57.21 1.17.21 1.78 0 4.42-3.58 8-8 8s-8-3.58-8-8 3.58-8 8-8c1.58 0 3.04.46 4.28 1.25l1.44-1.44C16.1 2.67 14.13 2 12 2 6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10c0-1.19-.22-2.33-.6-3.39z'></path>
                </svg>
                <span class='contentbox fz-20 fw-500 title inter'>
                  commitment to transparency, compliance with relevant
                  regulations
                </span>
              </li>
              <li
                class='d-flex gap-2 align-items-center wow fadeInUp'
                data-wow-duration='1.7s'
              >
                <svg
                  class='MuiSvgIcon-root MuiSvgIcon-fontSizeMedium base css-i4bv87-MuiSvgIcon-root'
                  focusable='false'
                  aria-hidden='true'
                  viewBox='0 0 24 24'
                  data-testid='TaskAltIcon'
                >
                  <path d='M22 5.18 10.59 16.6l-4.24-4.24 1.41-1.41 2.83 2.83 10-10zm-2.21 5.04c.13.57.21 1.17.21 1.78 0 4.42-3.58 8-8 8s-8-3.58-8-8 3.58-8 8-8c1.58 0 3.04.46 4.28 1.25l1.44-1.44C16.1 2.67 14.13 2 12 2 6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10c0-1.19-.22-2.33-.6-3.39z'></path>
                </svg>
                <span class='contentbox fz-20 fw-500 title inter'>
                  We prioritize the security and confidentiality of this
                  information,
                </span>
              </li>
            </ul>

            <h2 class='title mb-4 wow fadeInUp' data-wow-duration='1.2s'>
              Use of information
            </h2>
            <p
              class='ptext2 fs-6  fw-400 inter wow fadeInUp mb-4'
              data-wow-duration='1.4s'
            >
              The information collected during the provision of Accounting &
              Payroll Processing Services is used solely for the purpose of
              delivering accurate and efficient financial services. We employ
              this data to facilitate payroll processing, accounting tasks, and
              related functions essential to your business operations. Your
              information is handled with the utmost care, ensuring
              confidentiality and compliance with relevant data protection
              regulations. We do not use your data for purposes beyond the scope
              of our services, and it is not shared with unauthorized third
              parties. Our commitment is to utilize your information
              responsibly, enhancing the quality and precision of the financial
              services we provide. For further details, please consult our
              comprehensive Privacy Policy
            </p>

            <h2 class='title mb-4 wow fadeInUp' data-wow-duration='1.2s'>
              Data security
            </h2>
            <p
              class='ptext2 fs-6  fw-400 inter wow fadeInUp mb-4'
              data-wow-duration='1.4s'
            >
              At Accupay, we prioritize the security of your data. Rigorous
              measures are in place to safeguard the confidentiality and
              integrity of the information entrusted to us. This includes robust
              encryption protocols, access controls, and regular security
              audits. We are committed to protecting your data from unauthorized
              access, disclosure, alteration, or destruction. Our dedicated
              security team ensures compliance with industry best practices and
              relevant data protection regulations.
            </p>

            <h2 class='title mb-4 wow fadeInUp' data-wow-duration='1.2s'>
              Data sharing and third parties
            </h2>
            <p
              class='ptext2 fs-6  fw-400 inter wow fadeInUp mb-4'
              data-wow-duration='1.4s'
            >
              We understand the importance of your privacy. Your data, collected
              for Accounting & Payroll Processing Services, is treated with
              utmost confidentiality. We do not share your information with
              third parties unless essential for service delivery. In such
              cases, strict contractual agreements ensure that third parties
              adhere to our privacy standards. Rest assured, your data is never
              sold, traded, or used for unrelated purposes. Our commitment is to
              transparency and responsible data handling. For more details on
              data sharing practices and the involvement of third parties,
              please review our comprehensive Privacy Policy. Your trust is
              paramount, and we take every measure to protect the security and
              confidentiality of your information
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default Privacypolicy
